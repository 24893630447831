import axios from 'axios';
import packageJson from '../../../package.json';

export default class DepartamentoService {
    
    getDepartamentosJson() {
        return axios.get('assets/demo/data/tickets/departamentos.json').then((res) => res.data.data);
    }

    getDepartamentos() {
        return axios.get(packageJson.service.url + '/catalog/departments/get').then((res) => res.data.response);
    }

    saveDepartamento(request) {
        return axios.post(packageJson.service.url + '/catalog/departments/post', request).then((res) => res.data.response.departamentoId);
    }

    updateDepartamento(request) {
        return axios.put(packageJson.service.url + '/catalog/departments/put', request).then((res) => res.data);
    }

    deleteDepartamento(id) {
        return axios.delete(packageJson.service.url + '/catalog/departments/delete/'+id).then((res) => res.data);
    }
}
