import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ActivoService from '../../service/tickets/ActivoService';
import CatalogoService from '../../service/tickets/CatalogoService';
import { format } from 'date-fns';

const CrudActivo = () => {
    let emptyActivo = {
        activoId: 0,
        descripcion: '',
        numeroInventario: '',
        modelo: '',
        serie: '',
        caracteristicas: '',
        empleadoId: 0,
        empleado: ''
    };

    const [activos, setActivos] = useState(null);
    const [activoDialog, setActivoDialog] = useState(false);
    const [deleteActivoDialog, setDeleteActivoDialog] = useState(false);
    const [deleteActivosDialog, setDeleteActivosDialog] = useState(false);
    const [activo, setActivo] = useState(emptyActivo);
    const [selectedActivos, setSelectedActivos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [empleados, setEmpleados] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const activoService = new ActivoService();
        activoService.getActivos().then((data) => setActivos(data));
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catempleado').then((data) => setEmpleados(data));
    }, []);

    const formatDate = (value) => {
        return format(value, 'yyyy-MM-dd HH:mm');
    };

    const openNew = () => {
        setActivo(emptyActivo);
        setSubmitted(false);
        setActivoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setActivoDialog(false);
    };

    const hideDeleteActivoDialog = () => {
        setDeleteActivoDialog(false);
    };

    const hideDeleteActivosDialog = () => {
        setDeleteActivosDialog(false);
    };

    const addNew = (activoId) => {
        let _activos = [...activos];
        let _activo = { ...activo };

        _activo.activoId = activoId;
        _activo.fechaRegistro = formatDate(Date.now());
        _activo.fechaModificacion = formatDate(Date.now());
        _activo.empleado = findEmpleadoById(_activo.empleadoId).descripcion;

        _activos.push(_activo);
        setActivos(_activos);
        //console.log('addNew, _activos -->' + JSON.stringify(_activos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Activo Creado', life: 3000 });

        setActivoDialog(false);
        setActivo(emptyActivo);

        return;
    };

    const editItem = () => {
        let _activos = [...activos];
        let _activo = { ...activo };

        const index = findIndexById(activo.activoId);
        _activo.fechaModificacion = formatDate(Date.now());
        _activo.empleado = findEmpleadoById(activo.empleadoId).descripcion;

        _activos[index] = _activo;
        setActivos(_activos);
        //console.log('editItem, _activos -->' + JSON.stringify(_activos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Activo Actualizado', life: 3000 });

        setActivoDialog(false);
        setActivo(emptyActivo);

        return;
    };

    const saveActivo = () => {
        setSubmitted(true);

        if (activo.descripcion.trim() && activo.numeroInventario.trim() && activo.modelo.trim() && activo.serie.trim() && activo.caracteristicas.trim() && activo.empleadoId) {
            let _activo = { ...activo };
            if (activo.activoId) {
                const activoService = new ActivoService();
                let _activoUpdate = { ...emptyActivo };
                _activoUpdate.activoId = activo.activoId;
                _activoUpdate.descripcion = activo.descripcion;
                _activoUpdate.numeroInventario = activo.numeroInventario;
                _activoUpdate.modelo = activo.modelo;
                _activoUpdate.serie = activo.serie;
                _activoUpdate.caracteristicas = activo.caracteristicas;
                _activoUpdate.empleadoId = activo.empleadoId;
                _activoUpdate.empleado = findEmpleadoById(activo.empleadoId).descripcion;
                activoService.updateActivo(_activoUpdate).then(() => editItem());
            } else {
                const activoService = new ActivoService();
                activoService.saveActivo(_activo).then((activoId) => addNew(activoId));
            }
            
        }
    };

    const editActivo = (activo) => {
        setActivo({ ...activo });
        setActivoDialog(true);
    };

    const confirmDeleteActivo = (activo) => {
        setActivo(activo);
        setDeleteActivoDialog(true);
    };

    const deleteActivo = () => {
        const activoService = new ActivoService();
        activoService.deleteActivo(activo.activoId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _activos = activos.filter((val) => val.activoId !== response.id);

            setActivos(_activos);
            setDeleteActivoDialog(false);
            setActivo(emptyActivo);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Activo Eliminado', life: 3000 });
        }
        else{
            setDeleteActivoDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (activoId) => {
        let index = -1;
        for (let i = 0; i < activos.length; i++) {
            if (activos[i].activoId === activoId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const findEmpleadoById = (empleadoId) => {
        if(empleados && empleados.length>0){
            for (let i = 0; i < empleados.length; i++) 
                if (empleados[i].id === empleadoId)
                    return empleados[i];
            return empleados[0];
        }
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedActivos = () => {
        const activoService = new ActivoService();
        for (let i = 0; i < selectedActivos.length; i++) {
            activoService.deleteActivo(selectedActivos[i].activoId);
        }

        let _activos = activos.filter((val) => !selectedActivos.includes(val));
        setActivos(_activos);
        setDeleteActivosDialog(false);
        setSelectedActivos(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Activos eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _Activo = { ...activo };
        _Activo[`${name}`] = val;

        setActivo(_Activo);
    };

    const onIdChange = (val, name) => {
        let _Activo = { ...activo };
        _Activo[`${name}`] = val;

        setActivo(_Activo);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };
    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };
    const NumeroInventarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Numero Inventario</span>
                {rowData.numeroInventario}
            </>
        );
    };
    const ModeloBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Modelo</span>
                {rowData.modelo}
            </>
        );
    };
    const SerieBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serie</span>
                {rowData.serie}
            </>
        );
    };
    const CaracteristicasBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Caracteristicas</span>
                {rowData.caracteristicas}
            </>
        );
    };
    const EmpleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Empleado</span>
                {rowData.empleado}
            </>
        );
    };
    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };
    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editActivo(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteActivo(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Activos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const activoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveActivo} />
        </>
    );
    const deleteActivoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActivoDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteActivo} />
        </>
    );
    const deleteActivosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActivosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedActivos} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={activos}
                        selection={selectedActivos}
                        onSelectionChange={(e) => setSelectedActivos(e.value)}
                        dataKey="activoId"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} niveles"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de activos."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Descripción" sortable body={descripcionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="numeroInventario" header="No. Inventario" sortable body={NumeroInventarioBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="modelo" header="Modelo" sortable body={ModeloBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="serie" header="Serie" sortable body={SerieBodyTemplate} headerStyle={{ width: '5%', minWidth: '10rem' }}></Column>
                        <Column field="caracteristicas" header="Caracteristicas" sortable body={CaracteristicasBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="empleado" header="Empleado" sortable body={EmpleadoBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>

                        <Column field="fechaRegistro" header="Fecha Alta" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Mod." body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={activoDialog} style={{ width: '450px' }} header="Activo Detalle" modal className="p-fluid" footer={activoDialogFooter} onHide={hideDialog}>
                        {activo.image && <img src={`assets/demo/images/activo/${activo.image}`} alt={activo.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       
                        <div className="field">
                            <label htmlFor="descripcion">Descripción</label>
                            <InputTextarea id="description" value={activo.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !activo.descripcion })}/>
                            {submitted && !activo.descripcion && <small className="p-invalid">Activo es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="numeroInventario">Número de Inventario</label>
                            <InputText id="numeroInventario" value={activo.numeroInventario} onChange={(e) => onInputChange(e, 'numeroInventario')} maxLength="25" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !activo.numeroInventario })}/>
                            {submitted && !activo.numeroInventario && <small className="p-invalid">Numero Inventario es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="modelo">Modelo</label>
                            <InputText id="modelo" value={activo.modelo} onChange={(e) => onInputChange(e, 'modelo')} maxLength="50" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !activo.modelo })}/>
                            {submitted && !activo.modelo && <small className="p-invalid">Modelo es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="serie">Serie</label>
                            <InputText id="serie" value={activo.serie} onChange={(e) => onInputChange(e, 'serie')} maxLength="50" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !activo.serie })}/>
                            {submitted && !activo.serie && <small className="p-invalid">Serie es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="caracteristicas">Caracteristicas</label>
                            <InputTextarea id="caracteristicas" value={activo.caracteristicas} onChange={(e) => onInputChange(e, 'caracteristicas')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !activo.caracteristicas })}/>
                            {submitted && !activo.caracteristicas && <small className="p-invalid">Caracteristicas es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="empleado">Empleado</label>
                            <Dropdown id="empleado" options={empleados} value={findEmpleadoById(activo.empleadoId)}  onChange={(e) => onIdChange(e.value.id, 'empleadoId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !activo.empleadoId })} ></Dropdown>
                            {submitted && !activo.empleadoId && <small className="p-invalid">Empleado es requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActivoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActivoDialogFooter} onHide={hideDeleteActivoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {activo && (
                                <span>
                                    Estas seguro que deseas borrar el Activo <b>{activo.descripcion}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActivosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActivosDialogFooter} onHide={hideDeleteActivosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {activo && <span>Estas seguro que deseas borrar los activos seleccionados?</span>}
                        </div>
                    </Dialog>
              
                </div>
            </div>
        </div>
    );
};

export default CrudActivo;
