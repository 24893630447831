import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Messages } from 'primereact/messages';
import SeguridadService from '../service/tickets/SeguridadService';
import TicketService from '../service/tickets/TicketService';
import Cookies from 'js-cookie';
 
export const Login = () => {
    let emptyUser = {
        username: '',
        password: ''
    };

    const navigate = useNavigate();
    const [user, setUser] = useState(emptyUser);
    const message = useRef();
    const inputRef = useRef(null);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    }

    const signIn = () => {
        if (user.username.trim() && user.password.trim()) {
            let _user = { ...user };
            const seguridadService = new SeguridadService();
            seguridadService.signInUser(_user).then((usuario) => signInUsuario(usuario));
        }
        else{
            inputRef.current.focus();
            message.current.show({ severity: 'info', content: 'El usuario y password es requerido.' });
        }
    }

    const signInUsuario = (response) => {
        if(response && response.valida){
            //console.log('usuario -->' +  JSON.stringify(response));
            const expirationTime = new Date(new Date().getTime() + 180 * 60 * 1000);
            Cookies.set('tokentickets', response.token, { sameSite: 'strict', secure: true, expires: expirationTime});
            localStorage.setItem('user', JSON.stringify(response));
            navigate('/');
        }
        else{
            //console.log('erorr usuario -->' +  JSON.stringify(response));
            navigate('/Access');
        }
    }

    const forgetPasswordUsuario = (res) => {
        if(res && res.message){    
            if(res.message === 'ok')
                message.current.show({ severity: 'success', content: 'Se genero el ticket número {0}.'.replace('{0}', res.id)});
            else
                message.current.show({ severity: 'error', content: res.message });
            setUser(emptyUser);
            inputRef.current.focus();
        }
    }

    const forgetPassword = () => {
        if (user.username.trim()) {
            let _user = { ...user };
            const ticketService = new TicketService();
            ticketService.forgetPassword(_user.username).then((res) => forgetPasswordUsuario(res));
        }
        else{
            inputRef.current.focus();
            message.current.show({ severity: 'info', content: 'El usuario es requerido.' });
        }
    }

    const desconectarUsuario = () => {
        //console.log('tokentickets antes -->' + Cookies.get('tokentickets'));
        if(Cookies.get('tokentickets')){
            Cookies.remove('tokentickets');
            //console.log('tokentickets despues -->' + Cookies.get('tokentickets'));
        }
    }

    useEffect(() => {
        desconectarUsuario();
        //console.log('DOM is loaded');
      }, []);

    return (
        <div className="login-body">
            <div className="login-panel"></div>
 
            <div className="login-content">
                <img src="assets/layout/images/tickets/logoOriginalAsenl.png"  style={{ width: '400px', marginBottom: '50px' }} alt="babylon-layout" />
                <h1>
                    <span>ACCESO </span> TICKETS ASENL
                </h1>
                <p>Bienvenido, por favor capture su usuario.</p>
                <div className="login-input-wrapper">
                    <InputText id="usuario" ref={inputRef}  autoFocus placeholder="Usuario" value={user.username} maxLength={100} onChange={(e) => onInputChange(e, 'username')}/>
                    <i className="pi pi-user"></i>
                </div>
                <div className="login-input-wrapper">
                    <InputText placeholder="Password" value={user.password} type='password' maxLength={100} onChange={(e) => onInputChange(e, 'password')} />
                    <i className="pi pi-lock"></i>
                </div>
                <div>
                    <Button label="Accesar" onClick={signIn}/>
                    <Button label="¿Olvidaste la contraseña?" onClick={forgetPassword} className="p-button-info p-button-text mr-2 mb-2"/>
                </div>
                <div className="col-12 md:col-4">
                    <Messages ref={message}/>
                </div>
            </div>
        </div>
    );
};
 