import axios from 'axios';
import packageJson from '../../../package.json';

export default class TicketService {
    
    getTicketsJson() {
        return axios.get('assets/demo/data/tickets/tickets.json').then((res) => res.data.data);
    }

    getTickets(id) {
        return axios.get(packageJson.service.url + '/ticket/tickets/get/'+id).then((res) => res.data.response);
    }

    getTicketLabel() {
        return axios.get(packageJson.service.url + '/ticket/tickets/get/ticketlabel').then((res) => res.data.message);
    }

    saveTicket(request) {
        return axios.post(packageJson.service.url + '/ticket/tickets/post', request).then((res) => res.data.response.ticketId);
    }

    forgetPassword(id) {
        return axios.post(packageJson.service.url + '/ticket/tickets/forgetpassword/'+id).then((res) => res.data);
    }

    updateTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/put', request).then((res) => res.data);
    }

    assignTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/assignticket', request).then((res) => res.data);
    }

    autorizationTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/autorizationticket', request).then((res) => res.data);
    }

    closeTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/closeticket', request).then((res) => res.data);
    }

    completedTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/completedticket', request).then((res) => res.data);
    }

    pendingApprovalTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/pendingapprovalticket', request).then((res) => res.data);
    }

    authorizedTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/authorizedticket', request).then((res) => res.data);
    }

    unauthorizedTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/unauthorizedTicket', request).then((res) => res.data);
    }

    cancelTicket(request) {
        return axios.put(packageJson.service.url + '/ticket/tickets/cancelticket', request).then((res) => res.data);
    }
}
