import axios from 'axios';
import packageJson from '../../../package.json';

export default class PisoService {
    
    getPisosJson() {
        return axios.get('assets/demo/data/tickets/pisos.json').then((res) => res.data.data);
    }

    getPisos() {
        return axios.get(packageJson.service.url + '/catalog/floors/get').then((res) => res.data.response);
    }

    savePiso(request) {
        return axios.post(packageJson.service.url + '/catalog/floors/post', request).then((res) => res.data.response.pisoId);
    }

    updatePiso(request) {
        return axios.put(packageJson.service.url + '/catalog/floors/put', request).then((res) => res.data);
    }

    deletePiso(id) {
        return axios.delete(packageJson.service.url + '/catalog/floors/delete/'+id).then((res) => res.data);
    }
}
