import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import PisoService from '../../service/tickets/PisoService';

const CrudPiso = () => {
    let emptyPiso = {
        pisoId: 0,
        descripcion: ''
    };

    const [pisos, setPisos] = useState(null);
    const [pisoDialog, setPisoDialog] = useState(false);
    const [deletePisoDialog, setDeletePisoDialog] = useState(false);
    const [deletePisosDialog, setDeletePisosDialog] = useState(false);
    const [piso, setPiso] = useState(emptyPiso);
    const [selectedPisos, setSelectedPisos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const pisoService = new PisoService();
        pisoService.getPisos().then((data) => setPisos(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setPiso(emptyPiso);
        setSubmitted(false);
        setPisoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPisoDialog(false);
    };

    const hideDeletePisoDialog = () => {
        setDeletePisoDialog(false);
    };

    const hideDeletePisosDialog = () => {
        setDeletePisosDialog(false);
    };

    const savePiso = () => {
        setSubmitted(true);

        if (piso.descripcion.trim()) {
            let _piso = { ...piso };
            if (piso.pisoId) {
                const pisoService = new PisoService();
                let _pisoUpdate = { ...emptyPiso };
                _pisoUpdate.pisoId = piso.pisoId;
                _pisoUpdate.descripcion = piso.descripcion;

                pisoService.updatePiso(_pisoUpdate).then(() => editItem());
            } else {
                const pisoService = new PisoService();
                pisoService.savePiso(_piso).then((pisoId) => addNew(pisoId));
            }
            
        }
    };

    const editPiso = (piso) => {
        setPiso({ ...piso });
        setPisoDialog(true);
    };

    const confirmDeletePiso = (piso) => {
        setPiso(piso);
        setDeletePisoDialog(true);
    };

    const deletePiso = () => {
        const pisoService = new PisoService();
        pisoService.deletePiso(piso.pisoId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _pisos = pisos.filter((val) => val.pisoId !== response.id);

            setPisos(_pisos);
            setDeletePisoDialog(false);
            setPiso(emptyPiso);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Piso Eliminado', life: 3000 });
        }
        else{
            setDeletePisoDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (pisoId) => {
        let index = -1;
        for (let i = 0; i < pisos.length; i++) {
            if (pisos[i].pisoId === pisoId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const addNew = (pisoId) => {
        let _pisos = [...pisos];
        let _piso = { ...piso };

        _piso.pisoId = pisoId;
        _piso.fechaRegistro = Date.now();
        _piso.fechaModificacion = Date.now();

        _pisos.push(_piso);
        setPisos(_pisos);
        console.log('addNew, _pisos -->' + JSON.stringify(_pisos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Piso Creado', life: 3000 });

        setPisoDialog(false);
        setPiso(emptyPiso);

        return;
    };

    const editItem = () => {
        let _pisos = [...pisos];
        let _piso = { ...piso };

        const index = findIndexById(piso.pisoId);
        _piso.fechaModificacion = formatDate(Date.now());

        _pisos[index] = _piso;
        setPisos(_pisos);
        //console.log('editItem, _pisos -->' + JSON.stringify(_pisos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Piso Actualizado', life: 3000 });

        setPisoDialog(false);
        setPiso(emptyPiso);

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };
    
    const deleteSelectedPisos = () => {
        const pisoService = new PisoService();
        for (let i = 0; i < selectedPisos.length; i++) {
            pisoService.deletePiso(selectedPisos[i].pisoId);
        }

        let _pisos = pisos.filter((val) => !selectedPisos.includes(val));
        setPisos(_pisos);
        setDeletePisosDialog(false);
        setSelectedPisos(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Pisos eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _piso = { ...piso };
        _piso[`${name}`] = val;

        setPiso(_piso);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPiso(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeletePiso(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Pisos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const pisoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={savePiso} />
        </>
    );
    const deletePisoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePisoDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deletePiso} />
        </>
    );
    const deletePisosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePisosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPisos} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={pisos}
                        selection={selectedPisos}
                        onSelectionChange={(e) => setSelectedPisos(e.value)}
                        dataKey="pisoId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pisos"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de piso."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '50%', minWidth: '20rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={pisoDialog} style={{ width: '450px' }} header="Piso" modal className="p-fluid" footer={pisoDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="descripcion">Descripcion</label>
                            <InputTextarea id="description" value={piso.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !piso.descripcion })}/>
                            {submitted && !piso.descripcion && <small className="p-invalid">Descripcion es requerida.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePisoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePisoDialogFooter} onHide={hideDeletePisoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {piso && (
                                <span>
                                    Estas seguro que deseas borrar el piso <b>{piso.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePisosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePisosDialogFooter} onHide={hideDeletePisosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {piso && <span>Estas seguro que deseas borrar los pisos seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudPiso;
