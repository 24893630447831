import axios from 'axios';
import packageJson from '../../../package.json';

export default class ActivoService {
    
    getActivosJson() {
        return axios.get('assets/demo/data/tickets/activos.json').then((res) => res.data.data);
    }

    getActivos() {
        return axios.get(packageJson.service.url + '/catalog/assets/get').then((res) => res.data.response);
    }

    saveActivo(request) {
        return axios.post(packageJson.service.url + '/catalog/assets/post', request).then((res) => res.data.response.activoId);
    }

    updateActivo(request) {
        return axios.put(packageJson.service.url + '/catalog/assets/put', request).then((res) => res.data);
    }

    deleteActivo(id) {
        return axios.delete(packageJson.service.url + '/catalog/assets/delete/'+id).then((res) => res.data);
    }
}
