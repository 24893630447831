import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ProveedorService from '../../service/tickets/ProveedorService';

const CrudProveedor = () => {
    let emptyProveedor = {
        proveedorId: 0,
        rfc: '',
        razonSocial: '',
        nombre: '',
        empadronado: '',
        giroProveedor: ''
    };

    const [proveedores, setProveedores] = useState(null);
    const [proveedorDialog, setProveedorDialog] = useState(false);
    const [deleteProveedorDialog, setDeleteProveedorDialog] = useState(false);
    const [deleteProveedoresDialog, setDeleteProveedoresDialog] = useState(false);
    const [proveedor, setProveedor] = useState(emptyProveedor);
    const [selectedProveedores, setSelectedProveedores] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const proveedorService = new ProveedorService();
        proveedorService.getProveedores().then((data) => setProveedores(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setProveedor(emptyProveedor);
        setSubmitted(false);
        setProveedorDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProveedorDialog(false);
    };

    const hideDeleteProveedorDialog = () => {
        setDeleteProveedorDialog(false);
    };

    const hideDeleteProveedoresDialog = () => {
        setDeleteProveedoresDialog(false);
    };

    const saveProveedor = () => {
        setSubmitted(true);

        if (proveedor.rfc.trim() && proveedor.razonSocial.trim() && proveedor.nombre.trim() && proveedor.empadronado.trim() && proveedor.empadronado.trim()) {
            let _proveedores = [...proveedores];
            let _proveedor = { ...proveedor };
            if (proveedor.proveedorId) {
                const index = findIndexById(proveedor.proveedorId);

                const proveedorService = new ProveedorService();
                proveedorService.updateProveedor(_proveedor);

                _proveedores[index] = _proveedor;
                setProveedores(_proveedores);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proveedor Actualizado', life: 3000 });
            } else {
                //const proveedorService = new ProveedorService();
                //_proveedor.proveedorId = proveedorService.saveProveedor(_proveedor);

                _proveedores.push(_proveedor);
                setProveedores(_proveedores);

                const proveedorService = new ProveedorService();
                proveedorService.saveProveedor(_proveedor).then((proveedorId) => setProveedorId(proveedorId));
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proveedor Creado', life: 3000 });
            }
            setProveedorDialog(false);
            setProveedor(emptyProveedor);
        }
    };

    const editProveedor = (proveedor) => {
        setProveedor({ ...proveedor });
        setProveedorDialog(true);
    };

    const confirmDeleteProveedor = (proveedor) => {
        setProveedor(proveedor);
        setDeleteProveedorDialog(true);
    };

    const deleteProveedor = () => {
        const proveedorService = new ProveedorService();
        proveedorService.deleteProveedor(proveedor.proveedorId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _proveedores = proveedores.filter((val) => val.proveedorId !== response.id);

            setProveedores(_proveedores);
            setDeleteProveedorDialog(false);
            setProveedor(emptyProveedor);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proveedor Eliminado', life: 3000 });
        }
        else{
            setDeleteProveedorDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (proveedorId) => {
        let index = -1;
        for (let i = 0; i < proveedores.length; i++) {
            if (proveedores[i].proveedorId === proveedorId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const setProveedorId = (proveedorId) => {
        let index = proveedores.length-1;
        console.log("proveedores["+index+"] after --> " + proveedores[index].proveedorId);
        proveedores[index].proveedorId = proveedorId;
        console.log("proveedores["+index+"] before --> " + proveedores[index].proveedorId);

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedProveedores = () => {
        const proveedorService = new ProveedorService();
        for (let i = 0; i < selectedProveedores.length; i++) {
            proveedorService.deleteProveedor(selectedProveedores[i].proveedorId);
        }

        let _proveedores = proveedores.filter((val) => !selectedProveedores.includes(val));
        setProveedores(_proveedores);
        setDeleteProveedoresDialog(false);
        setSelectedProveedores(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Proveedores eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _proveedor = { ...proveedor };
        _proveedor[`${name}`] = val;

        setProveedor(_proveedor);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const rfcBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">RFC</span>
                {rowData.rfc}
            </>
        );
    };

    const razonSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Razon Social</span>
                {rowData.razonSocial}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.nombre}
            </>
        );
    };

    const empadronadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Empadronado</span>
                {rowData.empadronado}
            </>
        );
    };

    const giroProveedorSocialBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Giro Proveedor</span>
                {rowData.giroProveedor}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProveedor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProveedor(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Proveedores</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const proveedorDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProveedor} />
        </>
    );
    const deleteProveedorDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedorDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteProveedor} />
        </>
    );
    const deleteProveedoresDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProveedoresDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProveedores} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={proveedores}
                        selection={selectedProveedores}
                        onSelectionChange={(e) => setSelectedProveedores(e.value)}
                        dataKey="proveedorId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} proveedores"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de proveedor."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="rfc" header="RFC" sortable body={rfcBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="razonSocial" header="Razon Social" sortable body={razonSocialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="empadronado" header="Empadronado" body={empadronadoBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="giroProveedor" header="Giro Proveedor" body={giroProveedorSocialBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={proveedorDialog} style={{ width: '450px' }} header="Proveedor Detalle" modal className="p-fluid" footer={proveedorDialogFooter} onHide={hideDialog}>
                        {proveedor.image && <img src={`assets/demo/images/proveedor/${proveedor.image}`} alt={proveedor.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="rfc">RFC</label>
                            <InputText id="rfc" value={proveedor.rfc} onChange={(e) => onInputChange(e, 'rfc')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.rfc })} />
                            {submitted && !proveedor.rfc && <small className="p-invalid">RFC es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="razonSocial">Razon Social</label>
                            <InputText id="razonSocial" value={proveedor.razonSocial} onChange={(e) => onInputChange(e, 'razonSocial')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.razonSocial })} />
                            {submitted && !proveedor.razonSocial && <small className="p-invalid">Razon Social es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={proveedor.nombre} onChange={(e) => onInputChange(e, 'nombre')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.nombre })} />
                            {submitted && !proveedor.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="empadronado">Empadronado</label>
                            <InputText id="empadronado" value={proveedor.empadronado} onChange={(e) => onInputChange(e, 'empadronado')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.empadronado })} />
                            {submitted && !proveedor.empadronado && <small className="p-invalid">Empadronado es requerida.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="giroProveedor">Giro Proveedor</label>
                            <InputText id="giroProveedor" value={proveedor.giroProveedor} onChange={(e) => onInputChange(e, 'giroProveedor')} required autoFocus className={classNames({ 'p-invalid': submitted && !proveedor.giroProveedor })} />
                            {submitted && !proveedor.giroProveedor && <small className="p-invalid">Giro Proveedor es requerida.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProveedorDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProveedorDialogFooter} onHide={hideDeleteProveedorDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {proveedor && (
                                <span>
                                    Estas seguro que deseas borrar el proveedor <b>{proveedor.razonSocial}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProveedoresDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProveedoresDialogFooter} onHide={hideDeleteProveedoresDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {proveedor && <span>Estas seguro que deseas borrar los proveedores seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudProveedor;
