import axios from 'axios';
import packageJson from '../../../package.json';

export default class EmpleadoService {
    
    getEmpleadosJson() {
        return axios.get('assets/demo/data/tickets/empleados.json').then((res) => res.data.data);
    }

    getEmpleados() {
        return axios.get(packageJson.service.url + '/catalog/employees/get').then((res) => res.data.response);
    }

    saveEmpleado(request) {
        return axios.post(packageJson.service.url + '/catalog/employees/post', request).then((res) => res.data.response.empleadoId);
    }

    updateEmpleado(request) {
        return axios.put(packageJson.service.url + '/catalog/employees/put', request).then((res) => res.data);
    }

    deleteEmpleado(id) {
        return axios.delete(packageJson.service.url + '/catalog/employees/delete/'+id).then((res) => res.data);
    }
}
