import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import UnidadService from '../../service/tickets/UnidadService';
import CatalogoService from '../../service/tickets/CatalogoService';

const CrudUnidad = () => {
    let emptyUnidad = {
        unidadId: 0,
        titularId: 0,
        titularNombre: '',
        numero: '',
        descripcion: ''
    };

    const [unidades, setUnidades] = useState(null);
    const [titulares, setTitulares] = useState(null);
    const [unidadDialog, setUnidadDialog] = useState(false);
    const [deleteUnidadDialog, setDeleteUnidadDialog] = useState(false);
    const [deleteUnidadesDialog, setDeleteUnidadesDialog] = useState(false);
    const [unidad, setUnidad] = useState(emptyUnidad);
    const [selectedUnidades, setSelectedUnidades] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const unidadService = new UnidadService();
        unidadService.getUnidades().then((data) => setUnidades(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catempleado').then((data) => setTitulares(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setUnidad(emptyUnidad);
        setSubmitted(false);
        setUnidadDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUnidadDialog(false);
    };

    const hideDeleteUnidadDialog = () => {
        setDeleteUnidadDialog(false);
    };

    const hideDeleteUnidadesDialog = () => {
        setDeleteUnidadesDialog(false);
    };

    const addNew = (unidadId) => {
        let _unidades = [...unidades];
        let _unidad = { ...unidad };

        _unidad.unidadId = unidadId;
        _unidad.fechaRegistro = formatDate(Date.now());
        _unidad.fechaModificacion = formatDate(Date.now());
        _unidad.titularNombre = findTitularById(_unidad.titularId).descripcion;

        _unidades.push(_unidad);
        setUnidades(_unidades);
        //console.log('addNew, _unidads -->' + JSON.stringify(_unidads));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Unidad Creada', life: 3000 });

        setUnidadDialog(false);
        setUnidad(emptyUnidad);

        return;
    };

    const editItem = () => {
        let _unidades = [...unidades];
        let _unidad = { ...unidad };

        const index = findIndexById(unidad.unidadId);
        _unidad.fechaModificacion = formatDate(Date.now());
        _unidad.titularNombre = findTitularById(unidad.titularId).descripcion;

        _unidades[index] = _unidad;
        setUnidades(_unidades);
        //console.log('editItem, _unidads -->' + JSON.stringify(_unidads));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Unidad Actualizada', life: 3000 });

        setUnidadDialog(false);
        setUnidad(emptyUnidad);

        return;
    };

    const saveUnidad = () => {
        setSubmitted(true);

        if (unidad.titularId && unidad.numero.trim() && unidad.descripcion.trim()) {
            let _unidad = { ...unidad };
            if (unidad.unidadId) {
                const unidadService = new UnidadService();
                let _unidadUpdate = { ...emptyUnidad };
                _unidadUpdate.unidadId = unidad.unidadId;
                _unidadUpdate.descripcion = unidad.descripcion;
                _unidadUpdate.titularId = unidad.titularId;
                _unidadUpdate.numero = unidad.numero;
                unidadService.updateUnidad(_unidadUpdate).then(() => editItem());
            } else {
                const unidadService = new UnidadService();
                unidadService.saveUnidad(_unidad).then((unidadId) => addNew(unidadId));
            }
            
        }
    };

    const editUnidad = (unidad) => {
        setUnidad({ ...unidad });
        setUnidadDialog(true);
    };

    const confirmDeleteUnidad = (unidad) => {
        setUnidad(unidad);
        setDeleteUnidadDialog(true);
    };

    const deleteUnidad = () => {
        const unidadService = new UnidadService();
        unidadService.deleteUnidad(unidad.unidadId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _unidades = unidades.filter((val) => val.unidadId !== response.id);

            setUnidades(_unidades);
            setDeleteUnidadDialog(false);
            setUnidad(emptyUnidad);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Unidad Eliminado', life: 3000 });
        }
        else{
            setDeleteUnidadDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (unidadId) => {
        let index = -1;
        if(unidades)
            for (let i = 0; i < unidades.length; i++) {
                if (unidades[i].unidadId === unidadId) {
                    index = i;
                    break;
                }
            }
        return index;
    };

    const findTitularById = (titularId) => {
        if(titulares && titulares.length>0){
            for (let i = 0; i < titulares.length; i++) 
                if (titulares[i].id === titularId)
                    return titulares[i];
            return titulares[0];
        }
    };

    const onIdChange = (val, name) => {
        let _unidad = { ...unidad };
        _unidad[`${name}`] = val;

        setUnidad(_unidad);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedUnidades = () => {
        const unidadService = new UnidadService();
        for (let i = 0; i < selectedUnidades.length; i++) {
            unidadService.deleteUnidad(selectedUnidades[i].unidadId);
        }

        let _unidades = unidades.filter((val) => !selectedUnidades.includes(val));
        setUnidades(_unidades);
        setDeleteUnidadesDialog(false);
        setSelectedUnidades(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Unidades eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _unidad = { ...unidad };
        _unidad[`${name}`] = val;

        setUnidad(_unidad);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const numeroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Número</span>
                {rowData.numero}
            </>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const titularNombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titular</span>
                {rowData.titularNombre}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUnidad(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteUnidad(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Unidades</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const unidadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUnidad} />
        </>
    );
    const deleteUnidadDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUnidadDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteUnidad} />
        </>
    );
    const deleteUnidadesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUnidadesDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUnidades} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={unidades}
                        selection={selectedUnidades}
                        onSelectionChange={(e) => setSelectedUnidades(e.value)}
                        dataKey="unidadId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} unidades"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de unidad."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="numero" header="Número" sortable body={numeroBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                        <Column field="titularNombre" header="Nombre del Titular" body={titularNombreBodyTemplate} sortable headerStyle={{ width: '24%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={unidadDialog} style={{ width: '450px' }} header="Unidad" modal className="p-fluid" footer={unidadDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="numero">Numero</label>
                            <InputText id="numero" value={unidad.numero} onChange={(e) => onInputChange(e, 'numero')} required autoFocus className={classNames({ 'p-invalid': submitted && !unidad.numero })} />
                            {submitted && !unidad.numero && <small className="p-invalid">Numero es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="descripcion">Descripcion</label>
                            <InputText id="descripcion" value={unidad.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required autoFocus className={classNames({ 'p-invalid': submitted && !unidad.descripcion })} />
                            {submitted && !unidad.descripcion && <small className="p-invalid">Descripcion es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="titular">Titular</label>
                            <Dropdown id="titular" options={titulares} value={findTitularById(unidad.titularId)}  onChange={(e) => onIdChange(e.value.id, 'titularId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !unidad.titularId })} ></Dropdown>
                            {submitted && !unidad.titularId && <small className="p-invalid">Titular es requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUnidadDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUnidadDialogFooter} onHide={hideDeleteUnidadDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {unidad && (
                                <span>
                                    Estas seguro que deseas borrar el unidad <b>{unidad.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUnidadesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUnidadesDialogFooter} onHide={hideDeleteUnidadesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {unidad && <span>Estas seguro que deseas borrar los unidades seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudUnidad;
