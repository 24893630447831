import axios from 'axios';
import packageJson from '../../../package.json';

export default class EstatusService {
    
    getEstatussJson() {
        return axios.get('assets/demo/data/tickets/status.json').then((res) => res.data.data);
    }

    getEstatuss() {
        return axios.get(packageJson.service.url + '/catalog/status/get').then((res) => res.data.response);
    }

    saveEstatus(request) {
        return axios.post(packageJson.service.url + '/catalog/status/post', request).then((res) => res.data.response.estatuId);
    }

    updateEstatus(request) {
        return axios.put(packageJson.service.url + '/catalog/status/put', request).then((res) => res.data);
    }

    deleteEstatus(id) {
        console.log("id-->"+id)
        return axios.delete(packageJson.service.url + '/catalog/status/delete/'+id).then((res) => res.data);
    }
}