import axios from 'axios';
import packageJson from '../../../package.json';

export default class EdificioService {
    
    getEdificiosJson() {
        return axios.get('assets/demo/data/tickets/edificios.json').then((res) => res.data.data);
    }

    getEdificios() {
        return axios.get(packageJson.service.url + '/catalog/buildings/get').then((res) => res.data.response);
    }

    saveEdificio(request) {
        return axios.post(packageJson.service.url + '/catalog/buildings/post', request).then((res) => res.data.response.edificioId);
    }

    updateEdificio(request) {
        return axios.put(packageJson.service.url + '/catalog/buildings/put', request).then((res) => res.data);
    }

    deleteEdificio(id) {
        return axios.delete(packageJson.service.url + '/catalog/buildings/delete/'+id).then((res) => res.data);
    }
}
