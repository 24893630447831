import axios from 'axios';
import packageJson from '../../../package.json';

export default class SeguridadService {
    
    getUsuarios() {
        return axios.get(packageJson.service.url + '/catalog/users/get').then((res) => res.data.response);
    }

    signInUser(request) {
        return axios.post(packageJson.service.url + '/security/users/validate', request).then((res) => res.data.response).catch((error) => error);
    }

    getUsuarioMenu(id) {
        return axios.get(packageJson.service.url + '/security/users/menu/'+id).then((res) => res.data.response);
    }

    getUsuarioAccion(id) {
        return axios.get(packageJson.service.url + '/security/users/accion/'+id).then((res) => res.data.response);
    }
}
