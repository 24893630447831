import axios from 'axios';
import packageJson from '../../../package.json';

export default class ProveedorService {
    
    getProveedoresJson() {
        return axios.get('assets/demo/data/tickets/proveedors.json').then((res) => res.data.data);
    }

    getProveedores() {
        return axios.get(packageJson.service.url + '/catalog/providers/get').then((res) => res.data.response);
    }

    saveProveedor(request) {
        return axios.post(packageJson.service.url + '/catalog/providers/post', request).then((res) => res.data.response.proveedorId);
    }

    updateProveedor(request) {
        return axios.put(packageJson.service.url + '/catalog/providers/put', request).then((res) => res.data);
    }

    deleteProveedor(id) {
        return axios.delete(packageJson.service.url + '/catalog/providers/delete/'+id).then((res) => res.data);
    }
}
