import React from 'react';

export const Access = () => {
    return (
        <div className="exception-body access-denied">
            <div className="exception-panel"></div>

            <div className="exception-content">
                <img src="assets/layout/images/tickets/logoOriginalAsenl.png" style={{ width: '400px'}} alt="babylon-layout" />
                <h1>
                    <span className="exception-name">ACCESO</span> DENEGADO
                </h1>
                <p>El <b>usuario</b> o la <b>contraseña</b> son invalidos.</p>
                <a href="#/Login">Regresar a al logeo</a>
            </div>
        </div>
    );
};
