import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const FormLayoutDemo = () => {
    const dropdownItems = [
        { name: 'Option 1', code: 'Option 1' },
        { name: 'Option 2', code: 'Option 2' },
        { name: 'Option 3', code: 'Option 3' },
        { name: 'Option 4', code: 'Option 4' }
    ];

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState(
        {
            name: '',
            secondName: '',
            address: '',
            city: '',
            state: '',
            zip: ''
        }
    );

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = formData;

        alert(data.name);
    };

    const setTicket = () => {
        const data = {
            name: 'Juan',
            secondName: 'LUis',
            address: 'Nose',
            city: '1232',
            state: '',
            zip: '123'
        }

        setFormData(data);

        setData([
        {   
            "id": 1000,
            "name": "James Butt",
            "country": {
              "name": "Algeria",
              "code": "dz"
            },
            "company": "Benton, John B Jr",
            "date": "2015-09-13",
            "status": "unqualified",
            "activity": 17,
            "representative": {
              "name": "Ioni Bowcher",
              "image": "ionibowcher.png"
            }
        }]);
    };

    useEffect(() => {
        setTicket();
    }, []);

    return (<>
        <form onSubmit={handleSubmit}>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Advanced</h5>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-6 md:col-12">
                                <label htmlFor="firstname2">Firstname</label>
                                <InputText name="name" type="text" value={formData.name} onChange={handleInputChange}/>
                            </div>

                            {formData.name && <div className="field col-6 md:col-12">
                                <label htmlFor="lastname2">Lastname</label>
                                <InputText name="secondName" type="text"  value={formData.secondName} onChange={handleInputChange}/>
                            </div>}

                            <div className="field col-12">
                                <label htmlFor="address">Address</label>
                                <InputTextarea name="address" rows="4" value={formData.address} onChange={handleInputChange} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="city">City</label>
                                <InputText name="city" value={formData.city} type="text" />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="state">State</label>
                                <Dropdown name="state" value={formData.state} onChange={handleInputChange} options={dropdownItems} optionLabel="name" placeholder="Select One"></Dropdown>
                            </div>
                            <div className="field col-12">
                                <label htmlFor="zip">Zip</label>
                                <InputText name="zip" value={formData.zip} type="text" onChange={handleInputChange} />
                            </div>
                            <div className="field col-12">

                            <DataTable value={data}>
                                <Column field="id" header="Id"></Column>
                                <Column field="name" header="Customer"></Column>
                            </DataTable>
                            </div>                       
                        </div>
                        <div>
                            <Button label="Submit" type='submit'></Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </>
    );
};

export default FormLayoutDemo;