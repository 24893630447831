import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import TicketService from '../../service/tickets/TicketService';
import CatalogoService from '../../service/tickets/CatalogoService';
import SeguridadService from '../../service/tickets/SeguridadService';
import { SelectButton } from 'primereact/selectbutton';
import { format } from 'date-fns';
import { Calendar } from 'primereact/calendar';

const CrudTicket = () => {
    let emptyTicket = {
        ticketId: 0,
        estatusId: 1,
        descripcionTarea: '',
        descripcionActividad: '',
        descripcionActivo: '',
        descripcionEstatus: '',
        descripcion: '',
        empleado: '',
        empleadoAsignado: '',
        tareaId: 0,
        actividadId: 0,
        activoId: 0,
        usuarioId: 0,
        usuarioAsignadoId: 0,
        comentario: '',
        fechaCompromiso: ''
    };

    let emptyTicketAction = {
        ticketId: 0,
        descripcion: '',
        usuarioId: 0,
        usuarioAsignadoId: 0,
        comentario:''
    };

    const [usuarioId, setUsuarioId] = useState('');
    const [empleadoId, setEmpleadoId] = useState('');
    const [tickets, setTickets] = useState(null);
    const [tareas, setTareas] = useState(null);
    const [actividades, setActividades] = useState(null);
    const [actividadesByTask, setActividadesByTask] = useState(null);
    const [activos, setActivos] = useState(null);
    const [estatuses, setEstatuses] = useState(null);
    const [ticketDialog, setTicketDialog] = useState(false);
    const [ticketEventDialog, setTicketEventDialog] = useState(false);
    const [validateTicketDialog, setValidateTicketDialog] = useState(false);
    const [ticket, setTicket] = useState(emptyTicket);
    const [selectedTickets, setSelectedTickets] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [empleados, setEmpleados] = useState(null);
    const [empleadosSoporte, setEmpleadosSoporte] = useState(null);
    const [empleadosAutoriza, setEmpleadosAutoriza] = useState(null);
    const [empleadoDisabled, setEmpleadoDisabled] = useState(false);
    const [empleadoVisible, setEmpleadoVisible] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [btnEvent, setBtnEvent] = useState('Aceptar');
    const [noticket, setNoTicket] = useState('');
    const [comentariosDisabled, setComentariosDisabled] = useState('');
    const [accionDestino, setAccionDestino] = useState('');
    const [usuarioAccion, setUsuarioAccion] = useState(null);
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);
    const [fechaToday] = useState(new Date());
    const [fechaTomorrow, setFechaTomorrow] = useState(null);
    
    const toast = useRef(null);
    const dt = useRef(null);
    
    let actions = {
        New: 'Nuevo',
        Edit: 'Editar',
        Assig: 'Asignar',
        Close: 'Cerrar',
        Finish: 'Terminar',
        RequestAutorization: 'Pedir Autorización',
        Autorize: 'Autorizar',
        Unauthorized: 'No Autorizar',
        ReOpen: 'Reabrir',
        View: 'Ver',
        Cancel: 'Cancelar'
    };

    let status = {
        Registrado: 1,
        Asignado: 2,
        Cerrado: 3,
        Terminado: 4,
        PorAutorizar: 5,
        Autorizado: 6,
        NoAutorizado: 7,
        Cancelado: 9
    };

    let estatusAction = [
        {"estatusId": status.Registrado, "action": actions.New },
        {"estatusId": status.Registrado, "action": actions.Edit },
        {"estatusId": status.Registrado, "action": actions.Assig },
        {"estatusId": status.Registrado, "action": actions.RequestAutorization},
        {"estatusId": status.Registrado, "action": actions.Cancel},
        {"estatusId": status.Registrado, "action": actions.View},
        {"estatusId": status.Asignado, "action": actions.New },
        {"estatusId": status.Asignado, "action": actions.Assig },
        {"estatusId": status.Asignado, "action": actions.Close},
        {"estatusId": status.Asignado, "action": actions.Cancel},
        {"estatusId": status.Asignado, "action": actions.View},
        {"estatusId": status.Cerrado, "action": actions.New },
        {"estatusId": status.Cerrado, "action": actions.Finish},
        {"estatusId": status.Cerrado, "action": actions.ReOpen},
        {"estatusId": status.Cerrado, "action": actions.View},
        {"estatusId": status.Terminado, "action": actions.New },
        {"estatusId": status.Terminado, "action": actions.View},
        {"estatusId": status.PorAutorizar, "action": actions.New },
        {"estatusId": status.PorAutorizar, "action": actions.Autorize},
        {"estatusId": status.PorAutorizar, "action": actions.Unauthorized},
        {"estatusId": status.PorAutorizar, "action": actions.View},
        {"estatusId": status.Autorizado, "action": actions.New },
        {"estatusId": status.Autorizado, "action": actions.Assig },
        {"estatusId": status.Autorizado, "action": actions.Close},
        {"estatusId": status.Autorizado, "action": actions.View},
        {"estatusId": status.NoAutorizado, "action": actions.New },
        {"estatusId": status.NoAutorizado, "action": actions.Finish},
        {"estatusId": status.NoAutorizado, "action": actions.View},
        {"estatusId": status.Cancelado, "action": actions.New },
        {"estatusId": status.Cancelado, "action": actions.View}
    ];

    useEffect(() => {
        const data = localStorage.getItem('user');
        if (data) {
            setUsuarioId(JSON.parse(data).usuarioId);
            setEmpleadoId(JSON.parse(data).empleadoId);
        }
    }, []);

    useEffect(() => {
        const tomorrow = new Date(fechaToday);
        tomorrow.setDate(fechaToday.getDate() + 1);
        setFechaTomorrow(tomorrow);
      }, [fechaToday])

    useEffect(() => {
        if(usuarioId !== ''){
            const seguridadService = new SeguridadService();
            seguridadService.getUsuarioAccion(usuarioId).then((data) => setUsuarioAccion(data));
            const ticketService = new TicketService();
            ticketService.getTickets(usuarioId).then((data) => setTickets(data));
            ticketService.getTicketLabel().then((message) => {setMessage(message); setMessageVisible(!(message===''));});
        }
    }, [usuarioId]);

    useEffect(() => {
        if(empleadoId !== ''){
            const catalogoService = new CatalogoService();
            catalogoService.getCatalogos('_cattarea', empleadoId).then((data) => setTareas(data));
            catalogoService.getCatalogos('_catactividad', empleadoId).then((data) => setActividades(data));
            catalogoService.getCatalogos('_catactivo', empleadoId).then((data) => setActivos(data));
            catalogoService.getCatalogos('_catestatus', empleadoId).then((data) => setEstatuses(data));
            catalogoService.getCatalogos('_catempleadosoporte', empleadoId).then((data) => setEmpleadosSoporte(data));
            catalogoService.getCatalogos('_CatEmpleadoAutoriza', empleadoId).then((data) => setEmpleadosAutoriza(data));
        }
    }, [empleadoId]);

    const formatDate = (value) => {
        return format(value, 'yyyy-MM-dd HH:mm');
    };
    
    const openNew = () => {
        setTicket(emptyTicket);
        setSubmitted(false);
        setTicketDialog(true);
        setTitulo('Registro de Tickets')
        setNoTicket('Ticket');
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTicketDialog(false);
    };

    const hideDialogAssign = () => {
        setSubmitted(false);
        setTicketEventDialog(false);
    };

    const hideValidateTicketDialog = () => {
        setValidateTicketDialog(false);
    };

    const showValidateTicketDialog = () => {
        setValidateTicketDialog(true);
    };

    const addNew = (ticketId) => {
        let _tickets = [...tickets];
        let _ticket = { ...ticket };

        _ticket.ticketId = ticketId;
        _ticket.estatusId = status.Registrado;
        _ticket.fechaRegistro = formatDate(Date.now());
        _ticket.fechaModificacion = formatDate(Date.now());
        _ticket.descripcionActivo = findActivoById(_ticket.activoId).descripcion;
        _ticket.descripcionTarea = findTareaById(_ticket.tareaId).descripcion;
        _ticket.descripcionEstatus = findEstatusById(status.Registrado).descripcion;
        _ticket.descripcionActividad = findActividadById(_ticket.actividadId).descripcion;

        _tickets.push(_ticket);
        setTickets(_tickets);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Se genero el ticket número {0}.'.replace('{0}', ticketId), life: 3000 });

        setTicketDialog(false);
        setTicket(emptyTicket);

        return;
    };

    const editItem = () => {
        let _tickets = [...tickets];
        let _ticket = { ...ticket };

        const index = findIndexById(ticket.ticketId);
        _ticket.fechaModificacion = formatDate(Date.now());
        _ticket.activoNombre = findActivoById(ticket.activoId).descripcion;
        _ticket.descripcionTarea = findTareaById(_ticket.tareaId).descripcion;
        _ticket.descripcionEstatus = findEstatusById(_ticket.estatusId).descripcion;
        _ticket.descripcionActividad = findActividadById(_ticket.actividadId).descripcion;

        _tickets[index] = _ticket;
        setTickets(_tickets);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Ticket Actualizada', life: 3000 });

        setTicketDialog(false);
        setTicket(emptyTicket);

        return;
    };

    const actionItem = () => {
        let _tickets = [...tickets];
        let _ticket = { ...ticket };
        let detalle = 'Se ha pasado a estatus {1} el ticket número {0}.'.replace('{0}', ticket.ticketId);
        let descripcionEstatus = '';

        const index = findIndexById(ticket.ticketId);
        _ticket.fechaModificacion = formatDate(Date.now());

        if(accionDestino === actions.Assig){
            _ticket.empleadoAsignado = findEmpleadoById(ticket.usuarioAsignadoId).descripcion;
            descripcionEstatus = findEstatusById(status.Asignado).descripcion;
        }

        if(accionDestino === actions.RequestAutorization){
            _ticket.empleadoAsignado = findEmpleadoById(ticket.usuarioAsignadoId).descripcion;
            descripcionEstatus = findEstatusById(status.PorAutorizar).descripcion;
        }

        if(accionDestino === actions.Autorize)
            descripcionEstatus = findEstatusById(status.Autorizado).descripcion;

        if(accionDestino === actions.Unauthorized)
            descripcionEstatus = findEstatusById(status.NoAutorizado).descripcion;

        if(accionDestino === actions.Cancel)
            descripcionEstatus = findEstatusById(status.Cancelado).descripcion;

        if(accionDestino === actions.Close)
            descripcionEstatus = findEstatusById(status.Cerrado).descripcion;

        if(accionDestino === actions.Finish)
            descripcionEstatus = findEstatusById(status.Terminado).descripcion;

        _ticket.descripcionEstatus = descripcionEstatus;
        detalle = detalle.replace('{1}', descripcionEstatus);

        _tickets[index] = _ticket;
        setTickets(_tickets);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: detalle, life: 3000 });
        setTicketEventDialog(false);
    
        return;
    };

    const saveTicket = () => {
        setSubmitted(true);

        if (ticket.tareaId && ticket.actividadId && ticket.activoId  && ticket.descripcion.trim()) {
            let _ticket = { ...ticket };
            _ticket.usuarioId = usuarioId;
            if (ticket.ticketId) {
                const ticketService = new TicketService();
                let _ticketUpdate = { ...emptyTicket };
                _ticketUpdate.ticketId = ticket.ticketId;
                _ticketUpdate.descripcionTarea = ticket.descripcionTarea;
                _ticketUpdate.descripcionActividad = ticket.descripcionActividad
                _ticketUpdate.descripcionActivo = ticket.descripcionActivo;
                _ticketUpdate.descripcionEstatus = ticket.descripcionEstatus;
                _ticketUpdate.empleado = ticket.empleado;
                _ticketUpdate.empleadoAsignado = ticket.empleadoAsignado;
                _ticketUpdate.curp = ticket.curp;
                _ticketUpdate.rfc = ticket.rfc;
                _ticketUpdate.estatusId = ticket.estatusId;
                _ticketUpdate.activoId = ticket.activoId;
                _ticketUpdate.descripcion = ticket.descripcion;
                _ticketUpdate.telefono = ticket.telefono;
                _ticketUpdate.celular = ticket.celular;
                _ticketUpdate.emailPersonal = ticket.emailPersonal;
                _ticketUpdate.emailOficial = ticket.emailOficial;
                _ticketUpdate.foto = ticket.foto;
                _ticketUpdate.actividadId = ticket.actividadId;
                _ticketUpdate.tareaId = ticket.tareaId;
                ticketService.updateTicket(_ticketUpdate).then(() => editItem());
            } else {
                const ticketService = new TicketService();
                ticketService.saveTicket(_ticket).then((ticketId) => addNew(ticketId));
            }
            
        }
    };

    const actionTicket = () => {
        setSubmitted(true);

        if (!ticket.ticketId)
            return;

        if((ticket.comentario && ticket.comentario.trim())){
            let _ticketAction = { ...emptyTicketAction };
            _ticketAction.ticketId = ticket.ticketId;
            _ticketAction.comentario = ticket.comentario;
            _ticketAction.usuarioId = usuarioId;
            
            if(accionDestino === actions.Assig && ticket.usuarioAsignadoId && ticket.fechaCompromiso){
                _ticketAction.usuarioAsignadoId = ticket.usuarioAsignadoId;
                _ticketAction.fechaCompromiso = ticket.fechaCompromiso;
                const ticketService = new TicketService();
                ticketService.assignTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.RequestAutorization && ticket.usuarioAsignadoId ){
                _ticketAction.usuarioAsignadoId = ticket.usuarioAsignadoId;
                const ticketService = new TicketService();
                ticketService.pendingApprovalTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.Autorize  ){
                const ticketService = new TicketService();
                ticketService.authorizedTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.Unauthorized  ){
                const ticketService = new TicketService();
                ticketService.unauthorizedTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.Cancel){
                const ticketService = new TicketService();
                ticketService.cancelTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.Close){
                const ticketService = new TicketService();
                ticketService.closeTicket(_ticketAction).then(() => actionItem());
            }
            if(accionDestino === actions.Finish){
                const ticketService = new TicketService();
                ticketService.completedTicket(_ticketAction).then(() => actionItem());
            }
        }
    };

    const validateTicketAction = (ticket, action) => {
        setTicket({ ...ticket });
        setAccionDestino(action);

        //console.log('ticket.estatusId => ' + ticket.estatusId)
        //console.log('action => ' + action)
        if(validateEstatusAction(ticket.estatusId, action)){
            editTicketAction(ticket);
        }
        else{
            showValidateTicketDialog(ticket);
        }
    }

    const validateEstatusAction = (estatusId, action) => {
        return estatusAction.some(({ estatusId: validEstatusId, action: validAction }) => {
            return estatusId === validEstatusId && action === validAction;
        });
    };

    const editTicketAction = (ticket) => {
        setSubmitted(false);
        setNoTicket('Ticket no. ' + ticket.ticketId);
        if(accionDestino === actions.Edit){
            setEmpleadoDisabled(false);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Actualización de Tickets');
            setBtnEvent('Guardar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.Assig){
            setEmpleadoDisabled(false);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Asignación de Tickets');
            setBtnEvent('Asignar');
            setComentariosDisabled(false);
            setEmpleados(empleadosSoporte);
        }
        if(accionDestino === actions.RequestAutorization){
            setEmpleadoDisabled(false);
            setEmpleadoVisible(false);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Solicitar Autorización');
            setBtnEvent('Solicitar Autorización');
            setComentariosDisabled(false);
            setEmpleados(empleadosAutoriza);
        }
        if(accionDestino === actions.Unauthorized){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Autorización Denegada');
            setBtnEvent('Guardar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.Autorize){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(false);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Autorizar');
            setBtnEvent('Autorizar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.Cancel){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Cancelación de Tickets');
            setBtnEvent('Guardar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.Close){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Ejecución de Tickets');
            setBtnEvent('Guardar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.Finish){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Validación de Tickets');
            setBtnEvent('Guardar');
            setComentariosDisabled(false);
        }
        if(accionDestino === actions.View){
            setEmpleadoDisabled(true);
            setEmpleadoVisible(true);
            setTicket({ ...ticket });
            setTicketEventDialog(true);
            setTitulo('Consulta de Tickets');
            setBtnEvent('Cerrar');
            setComentariosDisabled(true);
        }
    };

    const findIndexById = (ticketId) => {
        let index = -1;
        if(tickets)
            for (let i = 0; i < tickets.length; i++) {
                if (tickets[i].ticketId === ticketId) {
                    index = i;
                    break;
                }
            }
        return index;
    };

    const findActivoById = (activoId) => {
        if(activos && activos.length>0){
            for (let i = 0; i < activos.length; i++) 
                if (activos[i].id === activoId)
                    return activos[i];
            return activos[0];
        }
    };

    const findEmpleadoById = (empleadoId) => {
        if(empleados && empleados.length>0){
            for (let i = 0; i < empleados.length; i++) 
                if (empleados[i].id === empleadoId)
                    return empleados[i];
            return empleados[0];
        }
    };

    const findTareaById = (tareaId) => {
        if(tareas && tareas.length>0){
            for (let i = 0; i < tareas.length; i++) 
                if (tareas[i].id === tareaId)
                    return tareas[i];
            return tareas[0];
        }
    };

    const findEstatusById = (estatusId) => {
        if(estatuses && estatuses.length>0){
            for (let i = 0; i < estatuses.length; i++) 
                if (estatuses[i].id === estatusId)
                    return estatuses[i];
            return estatuses[0];
        }
    };

    const findActividadById = (actividadId) => {
        if(actividades && actividades.length>0){
            for (let i = 0; i < actividades.length; i++) 
                if (actividades[i].id === actividadId)
                    return actividades[i];
            return actividades[0];
        }
    };

    const findActividadByTaskId = (taskId) => {
        let actividadesTask = [];
        actividadesTask.push(actividades[0]);
        if(actividades && actividades.length>0){
            for (let i = 0; i < actividades.length; i++) 
                if (actividades[i].padreId === taskId)
                    actividadesTask.push(actividades[i]);
        }
        return actividadesTask;
    };

    const onIdChange = (val, name) => {
        let _ticket = { ...ticket };
        _ticket[`${name}`] = val;

        setTicket(_ticket);
    }

    const onTaskChange = (value) => {
        if(value && value.id){
            let _ticket = { ...ticket };
            _ticket[`tareaId`] = value.id;
            _ticket[`actividadId`] = 0;
            setActividadesByTask(findActividadByTaskId(value.id));
            setTicket(_ticket);
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _ticket = { ...ticket };
        _ticket[`${name}`] = val;

        setTicket(_ticket);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const ticketIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ticket</span>
                {rowData.ticketId}
            </>
        );
    };

    const descripcionTareaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tarea</span>
                {rowData.descripcionTarea}
            </>
        );
    };

    const descripcionActividadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Actividad</span>
                {rowData.descripcionActividad}
            </>
        );
    };

    const descripcionActivoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Activo</span>
                {rowData.descripcionActivo}
            </>
        );
    };

    const descripcionEstatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.descripcionEstatus}
            </>
        );
    };

    const empleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Empleado</span>
                {rowData.empleado}
            </>
        );
    };

    const empleadoAsignadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Asignado A:</span>
                {rowData.empleadoAsignado}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };
    
    const actionBodyTemplate = (rowData) => {
        if (!Array.isArray(usuarioAccion)) {
            return <div className="actions"><Button key={actions.View} icon="pi pi-search" className="p-button-rounded p-button-info mr-2" tooltip={actions.View} onClick={() => validateTicketAction(rowData, actions.View)} /></div>
        }

        return (
            <div className="actions">
                {usuarioAccion.map(item => (
                <Button key={item.accionId} icon={item.icon} className={item.class} tooltip={item.name} onClick={() => validateTicketAction(rowData, item.name)} />
                ))}
            </div>
        );
    };
    

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Tickets</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const ticketDialogFooter = (
        <>
            <Button label="Cancelar" className="p-button-rounded p-button-warning mr-2 mb-2" onClick={hideDialog} />
            <Button label="Guardar" className="p-button-rounded p-button-success mr-2 mb-2" onClick={saveTicket} />
        </>
    );

    const ticketEventDialogFooter = (
        <>
            <Button label="Cancelar" className="p-button-rounded p-button-warning mr-2 mb-2" onClick={hideDialogAssign} />
            <Button label={btnEvent} className="p-button-rounded p-button-success mr-2 mb-2" onClick={actionTicket} />
        </>
    );

    const validateTicketDialogFooter = (
        <>
            <Button label="Aceptar" icon="pi pi-check" className="p-button-rounded p-button-info mr-2 mb-2" onClick={hideValidateTicketDialog}/>
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={tickets}
                        selection={selectedTickets}
                        onSelectionChange={(e) => setSelectedTickets(e.value)}
                        dataKey="ticketId"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} tickets"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de ticket."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="ticketId" header="Ticket" body={ticketIdBodyTemplate} sortable headerStyle={{ width: '5%', minWidth: '3rem' }}></Column>
                        <Column field="descripcionTarea" header="Tarea" body={descripcionTareaBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="descripcionActividad" header="Actividad" body={descripcionActividadBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '8rem' }}></Column>
                        <Column field="descripcionActivo" header="Activo" body={descripcionActivoBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '8rem' }}></Column>
                        <Column field="empleado" header="Empleado" body={empleadoBodyTemplate} sortable headerStyle={{ width: '15%', minWidth: '8rem' }}></Column>
                        <Column field="empleadoAsignado" header="Asignado A:" body={empleadoAsignadoBodyTemplate} sortable headerStyle={{ width: '13%', minWidth: '5rem' }}></Column>
                        <Column field="descripcionEstatus" header="Estatus" body={descripcionEstatusBodyTemplate} sortable headerStyle={{ width: '5%', minWidth: '3rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Mod." body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '4rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ticketDialog} style={{ width: '850px' }} header={ noticket } modal className="p-fluid" footer={ticketDialogFooter} onHide={hideDialog}>
                        <div className="field">                        
                            <div>
                                <h5>{titulo}</h5>
                            </div>
                            <SelectButton options={tareas} value={findTareaById(ticket.tareaId)}  onChange={(e) => onTaskChange(e.value)}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !ticket.tareaId })} ></SelectButton>
                            {submitted && !ticket.tareaId && <small className="p-invalid">Tarea es requerido.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="actividadId">Solicitud</label>
                            <Dropdown id="actividadId" options={actividadesByTask} value={findActividadById(ticket.actividadId)}  onChange={(e) => onIdChange(e.value.id, 'actividadId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !ticket.actividadId })} ></Dropdown>
                            {submitted && !ticket.actividadId && <small className="p-invalid">Actividad es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="activoId">Activo</label>
                            <Dropdown id="activoId" options={activos} value={findActivoById(ticket.activoId)}  onChange={(e) => onIdChange(e.value.id, 'activoId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !ticket.activoId })} ></Dropdown>
                            {submitted && !ticket.activoId && <small className="p-invalid">Activo es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="descripcion">Descripción</label>
                            <InputTextarea id="descripcion" value={ticket.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="3000" required rows={10} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !ticket.descripcion })}/>
                            {submitted && !ticket.descripcion && <small className="p-invalid">Dirección es requerida.</small>}
                        </div>
                        <div className="field">
                            <Button visible={messageVisible} label={message} className="p-button-outlined p-button-info mr-2 mb-2" />
                        </div>
                        
                    </Dialog>

                    <Dialog visible={ticketEventDialog} style={{ width: '850px' }} header={noticket + ' - ' + titulo}  modal className="p-fluid" footer={ticketEventDialogFooter} onHide={hideDialogAssign}>
                        <div className="field">
                            <SelectButton id="tareaIdAssign" options={tareas} value={findTareaById(ticket.tareaId)}  optionLabel="descripcion" disabled ></SelectButton>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="actividadIAssign">Solicitud</label>
                                    <Dropdown id="actividadIdAssign" options={actividades} value={findActividadById(ticket.actividadId)} optionLabel="descripcion" disabled></Dropdown>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="activoIdAssign">Activo</label>
                                    <Dropdown id="activoIdAssign" options={activos} value={findActivoById(ticket.activoId)}  optionLabel="descripcion"disabled ></Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="descripcionAssign">Descripción</label>
                            <InputTextarea id="descripcionAssign" value={ticket.descripcion} maxLength="3000" required rows={10} cols={20} disabled/>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="usuarioAsignadoId">Asignado a</label>
                                    <Dropdown visible={empleadoVisible} id="usuarioAsignadoId" options={empleados} value={findEmpleadoById(ticket.usuarioAsignadoId)}  onChange={(e) => onIdChange(e.value.id, 'usuarioAsignadoId')}  optionLabel="descripcion" placeholder="Seleccione una Opción" disabled={empleadoDisabled} required autoFocus className={classNames({ 'p-invalid': submitted && (!ticket.usuarioAsignadoId && ticket.estatusId === status.Registrado)})} ></Dropdown>
                                    {submitted && (!ticket.usuarioAsignadoId && ticket.estatusId === status.Registrado) && ticket.estatusId === status.Registrado && <small className="p-invalid">Empleado asignado es requerido.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="fechaComp">Fecha Compromiso</label>
                                    <Calendar type='date' id="fechaComp" showIcon showTime dateFormat="yy-mm-dd" hourFormat="12" minDate={fechaTomorrow} value={new Date(ticket.fechaCompromiso)} onChange={(e) => onInputChange(e, 'fechaCompromiso')} disabled={empleadoDisabled} required className={classNames({ 'p-invalid': submitted && !ticket.fechaCompromiso })} ></Calendar>
                                    {submitted && !ticket.fechaCompromiso && <small className="p-invalid">Fecha Compromiso es requerido.</small>}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="comentario">Comentario</label>
                            <InputTextarea id="comentario" value={ticket.comentario} onChange={(e) => onInputChange(e, 'comentario')} maxLength="1000" required rows={5} cols={10} disabled={comentariosDisabled} autoFocus className={classNames({ 'p-invalid': submitted && !ticket.comentario })}/>
                            {submitted && !ticket.comentario && <small className="p-invalid">Comentario es requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={validateTicketDialog} style={{ width: '450px' }} header="Información" modal footer={validateTicketDialogFooter} onHide={hideValidateTicketDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-info-circle mr-3" style={{ fontSize: '2rem' }} />
                            {ticket && (
                                <span>
                                    No se puede realizar la accion "<b>{accionDestino}</b>" para el ticket número {ticket.ticketId} cuando se encuentra en el estatus "<b>{ticket.descripcionEstatus}</b>".
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudTicket;
