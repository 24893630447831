import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Galleria } from 'primereact/galleria';
import ScannerService from '../../service/digitaliza/ScannerService';
import OcrComponent from '../../pages/digitaliza/OcrComponent'
import PhotoService from '../../service/PhotoService';

const FormLayoutDemo = () => {
    let emptyDigitaliza = {
        ticketId: 0,
        estatusId: 1,
        descripcionTarea: '',
        descripcionActividad: '',
        descripcionActivo: '',
        descripcionEstatus: '',
        descripcion: '',
        empleado: '',
        empleadoAsignado: '',
        tareaId: 0,
        actividadId: 0,
        activoId: 0,
        usuarioId: 0,
        usuarioAsignadoId: 0,
        comentario: '',
        webCors: '',
        webCorsEnable: true,
        accion:''
    };

    let actions = {
        WebCORSEnable: '0',
        ScannerStatus: '1',
        StartSession: '2',
        StartScanning: '3',
        SessionStatus: '4',
        SessionConfiguration: '5',
        SessionImage: '6',
        SessionMetaData: '7',
        StopScanning: '8',
        EndSession: '9'
    };

    const galleriaResponsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const galleriaItemTemplate = (item) => <img src={`assets/${item.itemImageSrc}`} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    const galleriaThumbnailTemplate = (item) => <img src={`assets/${item.thumbnailImageSrc}`} alt={item.alt} style={{ width: '100%', display: 'block' }} />;

    let accion = -1;

    const [digitaliza, setDigitaliza] = useState(emptyDigitaliza);
    const [dropdownItem, setDropdownItem] = useState(null);
    const [images, setImages] = useState([]);

    const actionScanner = (action) => {
        let _digitaliza = { ...digitaliza };
        console.log('_digitaliza ==> ' + JSON.stringify(_digitaliza));
        
        if(action === actions.WebCORSEnable){
            const scannerService = new ScannerService();
            scannerService.WebCorsEnable(_digitaliza.webCorsEnable).then((res) => actionresponse(res));
        }
        if(action === actions.ScannerStatus){
            const scannerService = new ScannerService();
            scannerService.getScannerStatus().then((res) => actionresponse(res));
        }
        if(action === actions.StartSession){
            const scannerService = new ScannerService();
            scannerService.startSession().then((res) => actionresponse(res));
        }
        if(action === actions.StartScanning){
            const scannerService = new ScannerService();
            scannerService.startScanning(_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === actions.SessionStatus){
            const scannerService = new ScannerService();
            scannerService.getSessionStatus(_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === actions.SessionConfiguration){
            const scannerService = new ScannerService();
            scannerService.getSessionConfiguration(_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === actions.SessionImage){
            const scannerService = new ScannerService();
            scannerService.getSessionImage(1,_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === actions.SessionMetaData){
            const scannerService = new ScannerService();
            scannerService.getSessionMetaData(1,_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === actions.StopScanning){
            const scannerService = new ScannerService();
            scannerService.stopScanning(_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        if(action === action.EndSession){
            const scannerService = new ScannerService();
            scannerService.endSession(_digitaliza.sessionId).then((res) => actionresponse(res));
        }
        accion = action;
        return;
    };

    const actionresponse = (response) => {        
        let _digitaliza = { ...digitaliza };
        
        if(accion === actions.WebCORSEnable){
            _digitaliza.webCors = JSON.stringify(response, null, 2);
            _digitaliza.btnWebCORSEnable = false;
        }
        if(accion === actions.ScannerStatus){
            _digitaliza.scannerStatus = JSON.stringify(response, null, 2);
            _digitaliza.btnScannerStatus = false;
        }
        if(accion === actions.StartSession){
            _digitaliza.sessionId = response.SessionId;
            _digitaliza.startSession = JSON.stringify(response, null, 2);
            _digitaliza.btnStartSession = false;
        }
        if(accion === actions.StartScanning){
            _digitaliza.startScanning = JSON.stringify(response, null, 2);
            _digitaliza.btnStartScanning = false;
        }
        if(accion === actions.SessionStatus){
            _digitaliza.sessionStatus = JSON.stringify(response, null, 2);
            _digitaliza.btnSessionStatus = false;
        }
        if(accion === actions.SessionConfiguration){
            _digitaliza.sessionConfiguration = JSON.stringify(response, null, 2);
            _digitaliza.btnSessionConfiguration = false;
        }
        if(accion === actions.SessionImage){
            _digitaliza.sessionImage = JSON.stringify(response, null, 2);
            _digitaliza.btnSessionImage = false;
        }
        if(accion === actions.SessionMetaData){
            _digitaliza.sessionMetaData = JSON.stringify(response, null, 2);
            _digitaliza.btnSessionMetaData = false;
        }
        if(accion === actions.StopScanning){
            _digitaliza.stopScanning = JSON.stringify(response, null, 2);
            _digitaliza.btnStopScanning = false;
        }
        if(accion === actions.EndSession){
            _digitaliza.endSession = JSON.stringify(response, null, 2);
            _digitaliza.btnEndSession = false;
        }
        
        setDigitaliza(_digitaliza);
        
        return;
    };

    const dropdownItems = [
        { name: 'Option 1', code: 'Option 1' },
        { name: 'Option 2', code: 'Option 2' },
        { name: 'Option 3', code: 'Option 3' }
    ];

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _digitaliza = { ...digitaliza };
        _digitaliza[`${name}`] = val;

        setDigitaliza(_digitaliza);
    };

    const onButtonClick = (name, _action) => {
        ///setAccionDestino(action);
        //console.log('action ==> ' + _action);
        
        let _digitaliza = { ...digitaliza };
        _digitaliza[`${name}`] = true;
        _digitaliza.scannerStatus = '';
        _digitaliza.accion = _action;
        setDigitaliza(_digitaliza);
        actionScanner(_action);
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card p-fluid">
                    <h5>Scanner</h5>
                    <div className="formgroup-inline">
                        <div className="field">
                            <label htmlFor="scannerName" className="p-sr-only">
                            scannerName
                            </label>
                            <InputText id="scannerName" type="text" value={digitaliza.scannerName} onChange={(e) => onInputChange(e, 'scannerName')} placeholder="Scanner Name / IP" />
                        </div>
                        <div className="field">
                            <label htmlFor="domain" className="p-sr-only">
                            domain
                            </label>
                            <InputText id="domain" type="text" value={digitaliza.domain} onChange={(e) => onInputChange(e, 'domain')} placeholder="Domain" />
                        </div>
                        <div className="field">
                            <label htmlFor="sessionId" className="p-sr-only">
                            sessionId
                            </label>
                            <InputText id="sessionId" disabled type="text" value={digitaliza.sessionId} onChange={(e) => onInputChange(e, 'sessionId')} placeholder="Session ID" />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="webCors" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Web Cors Enable" icon="pi pi-search" loading={digitaliza.btnWebCORS} onClick={(e) => onButtonClick('btnWebCorsEnable', actions.WebCORSEnable)}></Button>  
                        </label>
                        <div className="col-12 md:col-6">
                            <InputTextarea id="webCors" type="text" value={digitaliza.webCors} onChange={(e) => onInputChange(e, 'webCors')} rows={2} cols={10} />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="scannerStatus" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Scanner Status" icon="pi pi-search" loading={digitaliza.btnScannerStatus} onClick={(e) => onButtonClick('btnScannerStatus', actions.ScannerStatus)}></Button>  
                        </label>
                        <label htmlFor="startSession" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Start Session" icon="pi pi-search" loading={digitaliza.btnStartSession} onClick={(e) => onButtonClick('btnStartSession', actions.StartSession)}></Button>  
                        </label>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-6">
                            <InputTextarea id="scannerStatus" type="text" value={digitaliza.scannerStatus} onChange={(e) => onInputChange(e, 'scannerStatus')} rows={5} cols={10} />
                        </div>
                        <div className="col-12 md:col-6">
                            <InputTextarea id="startSession" type="text" value={digitaliza.startSession} onChange={(e) => onInputChange(e, 'startSession')} rows={5} cols={10} />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="startScanning" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Start Scanning" icon="pi pi-search" loading={digitaliza.btnStartScanning} onClick={(e) => onButtonClick('btnStartScanning', actions.StartScanning)}></Button>  
                        </label>
                        <label htmlFor="sessionStatus" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Session Status" icon="pi pi-search" loading={digitaliza.btnSessionStatus} onClick={(e) => onButtonClick('btnSessionStatus', actions.SessionStatus)}></Button>  
                        </label>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-6">
                            <InputTextarea id="startScanning" type="text" value={digitaliza.startScanning} onChange={(e) => onInputChange(e, 'startScanning')} rows={5} cols={10} />
                        </div>
                        <div className="col-12 md:col-6">
                            <InputTextarea id="sessionStatus" type="text" value={digitaliza.sessionStatus} onChange={(e) => onInputChange(e, 'sessionStatus')} rows={5} cols={10} />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="sessionImage" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Session Image" icon="pi pi-search" loading={digitaliza.btnSessionImage} onClick={(e) => onButtonClick('btnSessionImage', actions.SessionImage)}></Button>  
                        </label>
                        <label htmlFor="sessionMetaData" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Session Meta Data" icon="pi pi-search" loading={digitaliza.btnSessionMetaData} onClick={(e) => onButtonClick('btnSessionMetaData', actions.SessionMetaData)}></Button>  
                        </label>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-6">
                            <InputTextarea id="sessionImage" type="text" value={digitaliza.sessionImage} onChange={(e) => onInputChange(e, 'sessionImage')} rows={5} cols={10} />
                        </div>
                        <div className="col-12 md:col-6">
                            <InputTextarea id="sessionMetaData" type="text" value={digitaliza.sessionMetaData} onChange={(e) => onInputChange(e, 'sessionMetaData')} rows={5} cols={10} />
                        </div>
                    </div>
                    <div className="field grid">
                        <label htmlFor="stopScanning" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="Stop Scanning" icon="pi pi-search" loading={digitaliza.btnStopScanning} onClick={(e) => onButtonClick('btnStopScanning', actions.StopScanning)}></Button>  
                        </label>
                        <label htmlFor="endSession" className="col-12 mb-2 md:col-6 md:mb-0">
                            <Button label="End Session" icon="pi pi-search" loading={digitaliza.btnEndSession} onClick={(e) => onButtonClick('btnEndSession', actions.EndSession)}></Button>  
                        </label>
                    </div>
                    <div className="field grid">
                        <div className="col-12 md:col-6">
                            <InputTextarea id="stopScanning" type="text" value={digitaliza.stopScanning} onChange={(e) => onInputChange(e, 'stopScanning')} rows={5} cols={10} />
                        </div>
                        <div className="col-12 md:col-6">
                            <InputTextarea id="endSession" type="text" value={digitaliza.endSession} onChange={(e) => onInputChange(e, 'endSession')} rows={5} cols={10} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <h5>Documentos</h5>
                            <Galleria value={images} responsiveOptions={galleriaResponsiveOptions} numVisible={7} circular style={{ maxWidth: '800px', margin: 'auto' }} item={galleriaItemTemplate} thumbnail={galleriaThumbnailTemplate}></Galleria>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="card">
                    <h5>Advanced</h5>
                    <OcrComponent></OcrComponent>
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firstname2">Firstname</label>
                            <InputText id="firstname2" type="text" />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="lastname2">Lastname</label>
                            <InputText id="lastname2" type="text" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="address">Address</label>
                            <InputTextarea id="address" rows="4" />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="city">City</label>
                            <InputText id="city" type="text" />
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="state">State</label>
                            <Dropdown id="state" value={dropdownItem} onChange={(e) => setDropdownItem(e.value)} options={dropdownItems} optionLabel="name" placeholder="Select One"></Dropdown>
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="zip">Zip</label>
                            <InputText id="zip" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormLayoutDemo;
