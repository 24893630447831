import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import DepartamentoService from '../../service/tickets/DepartamentoService';

const CrudDepartamento = () => {
    let emptyDepartamento = {
        departamentoId: 0,
        descripcion: ''       
    };

    const [departamentos, setDepartamentos] = useState(null);
    const [departamentoDialog, setDepartamentoDialog] = useState(false);
    const [deleteDepartamentoDialog, setDeleteDepartamentoDialog] = useState(false);
    const [deleteDepartamentosDialog, setDeleteDepartamentosDialog] = useState(false);
    const [departamento, setDepartamento] = useState(emptyDepartamento);
    const [selectedDepartamentos, setSelectedDepartamentos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const departamentoService = new DepartamentoService();
        departamentoService.getDepartamentos().then((data) => setDepartamentos(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setDepartamento(emptyDepartamento);
        setSubmitted(false);
        setDepartamentoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setDepartamentoDialog(false);
    };

    const hideDeleteDepartamentoDialog = () => {
        setDeleteDepartamentoDialog(false);
    };

    const hideDeleteDepartamentosDialog = () => {
        setDeleteDepartamentosDialog(false);
    };

    const saveDepartamento = () => {
        setSubmitted(true);

        if (departamento.descripcion.trim()) {
            let _departamento = { ...departamento };
            if (departamento.departamentoId) {
                const departamentoService = new DepartamentoService();
                let _departamentoUpdate = { ...emptyDepartamento };
                _departamentoUpdate.departamentoId = departamento.departamentoId;
                _departamentoUpdate.descripcion = departamento.descripcion;

                departamentoService.updateDepartamento(_departamentoUpdate).then(() => editItem());
            } else {
                const departamentoService = new DepartamentoService();
                departamentoService.saveDepartamento(_departamento).then((departamentoId) => addNew(departamentoId));
            }
            
        }
    };

    const editDepartamento = (departamento) => {
        setDepartamento({ ...departamento });
        setDepartamentoDialog(true);
    };

    const confirmDeleteDepartamento = (departamento) => {
        setDepartamento(departamento);
        setDeleteDepartamentoDialog(true);
    };

    const deleteDepartamento = () => {
        const departamentoService = new DepartamentoService();
        departamentoService.deleteDepartamento(departamento.departamentoId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _departamentos = departamentos.filter((val) => val.departamentoId !== response.id);

            setDepartamentos(_departamentos);
            setDeleteDepartamentoDialog(false);
            setDepartamento(emptyDepartamento);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Departamento Eliminado', life: 3000 });
        }
        else{
            setDeleteDepartamentoDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (departamentoId) => {
        let index = -1;
        for (let i = 0; i < departamentos.length; i++) {
            if (departamentos[i].departamentoId === departamentoId) {
                index = i;
                break;
            }
        }

        return index;
    };

    const addNew = (departamentoId) => {
        let _departamentos = [...departamentos];
        let _departamento = { ...departamento };

        _departamento.departamentoId = departamentoId;
        _departamento.fechaRegistro = formatDate(Date.now());
        _departamento.fechaModificacion = formatDate(Date.now());

        _departamentos.push(_departamento);
        setDepartamentos(_departamentos);
        //console.log('addNew, _departamentos -->' + JSON.stringify(_departamentos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Departamento Creado', life: 3000 });

        setDepartamentoDialog(false);
        setDepartamento(emptyDepartamento);

        return;
    };

    const editItem = () => {
        let _departamentos = [...departamentos];
        let _departamento = { ...departamento };

        const index = findIndexById(departamento.departamentoId);
        _departamento.fechaModificacion = formatDate(Date.now());

        _departamentos[index] = _departamento;
        setDepartamentos(_departamentos);
        //console.log('editItem, _departamentos -->' + JSON.stringify(_departamentos));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Departamento Actualizado', life: 3000 });

        setDepartamentoDialog(false);
        setDepartamento(emptyDepartamento);

        return;
    };


    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedDepartamento = () => {
        const departamentoService = new DepartamentoService();
        for (let i = 0; i < selectedDepartamentos.length; i++) {
            departamentoService.deleteDepartamento(selectedDepartamentos[i].departamentoId);
        }

        let _departamentos = departamentos.filter((val) => !selectedDepartamentos.includes(val));
        setDepartamentos(_departamentos);
        setDeleteDepartamentosDialog(false);
        setSelectedDepartamentos(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Departamentos son eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _departamento = { ...departamento };
        _departamento[`${name}`] = val;

        setDepartamento(_departamento);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const nombreDepartamentoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    
    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDepartamento(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteDepartamento(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Departamento</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const departamentoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveDepartamento} />
        </>
    );
    const deleteDepartamentoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDepartamentoDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteDepartamento} />
        </>
    );
    const deleteDepartamentosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDepartamentosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDepartamento} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={departamentos}
                        selection={selectedDepartamentos}
                        onSelectionChange={(e) => setSelectedDepartamentos(e.value)}
                        dataKey="departamentoId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} departamentos"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de departamento."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Nombre" sortable body={nombreDepartamentoBodyTemplate} headerStyle={{ width: '50%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={departamentoDialog} style={{ width: '450px' }} header="Departamento Detalle" modal className="p-fluid" footer={departamentoDialogFooter} onHide={hideDialog}>
                        {departamento.image && <img src={`assets/demo/images/departamento/${departamento.image}`} alt={departamento.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                       
                        <div className="field">
                            <label htmlFor="descripcion">Nombre</label>
                            <InputTextarea id="description" value={departamento.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !departamento.descripcion })}/>
                             {submitted && !departamento.descripcion && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>
                     
                    </Dialog>

                    <Dialog visible={deleteDepartamentoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDepartamentoDialogFooter} onHide={hideDeleteDepartamentoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {departamento && (
                                <span>
                                    Estas seguro que deseas borrar el Departamento <b>{departamento.nivelDepartamento}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDepartamentosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDepartamentosDialogFooter} onHide={hideDeleteDepartamentosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {departamento && <span>Estas seguro que deseas borrar los departamentos seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudDepartamento;
