import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import EmpleadoService from '../../service/tickets/EmpleadoService';
import CatalogoService from '../../service/tickets/CatalogoService';
import { format } from 'date-fns';

const CrudEmpleado = () => {
    let emptyEmpleado = {
        empleadoId: 0,
        nombre: '',
        apellidoMaterno: '',
        apellidoPaterno: '',
        curp: '',
        rfc: '',
        nivelId: 0,
        descripcionNivel: '',
        departamentoId: 0,
        descripcionDepartamento: '',
        direccion: '',
        Extension: '',
        celular: '',
        emailPersonal: '',
        emailOficial: '',
        foto: '',
        unidadId: 0,
        descripcionUnidad: '',
        oficinaId: 0,
        descripcionOficina: ''
    };

    const [empleados, setEmpleados] = useState(null);
    const [departamentos, setDepartamentos] = useState(null);
    const [oficinas, setOficinas] = useState(null);
    const [niveles, setNiveles] = useState(null);
    const [unidades, setUnidades] = useState(null);
    const [empleadoDialog, setEmpleadoDialog] = useState(false);
    const [deleteEmpleadoDialog, setDeleteEmpleadoDialog] = useState(false);
    const [deleteEmpleadosDialog, setDeleteEmpleadosDialog] = useState(false);
    const [empleado, setEmpleado] = useState(emptyEmpleado);
    const [selectedEmpleados, setSelectedEmpleados] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [emailOficialValido, setEmailOficialValido] = useState(false);
    const [rfcValido, setRfcValido] = useState(false);
    const [curpValido, setCurpValido] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const empleadoService = new EmpleadoService();
        empleadoService.getEmpleados().then((data) => setEmpleados(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catdepartamento').then((data) => setDepartamentos(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catoficina').then((data) => setOficinas(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catnivel').then((data) => setNiveles(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catunidad').then((data) => setUnidades(data));
    }, []);

    const formatDate = (value) => {
        return format(value, 'yyyy-MM-dd HH:mm');
    };

    const openNew = () => {
        setEmpleado(emptyEmpleado);
        setSubmitted(false);
        setEmpleadoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEmpleadoDialog(false);
    };

    const hideDeleteEmpleadoDialog = () => {
        setDeleteEmpleadoDialog(false);
    };

    const hideDeleteEmpleadosDialog = () => {
        setDeleteEmpleadosDialog(false);
    };

    const addNew = (empleadoId) => {
        let _empleados = [...empleados];
        let _empleado = { ...empleado };

        _empleado.empleadoId = empleadoId;
        _empleado.fechaRegistro = formatDate(Date.now());
        _empleado.fechaModificacion = formatDate(Date.now());
        let nombreCompleto = `${_empleado.nombre} ${_empleado.apellidoPaterno} ${_empleado.apellidoMaterno}`;
        _empleado.nombreCompleto = nombreCompleto;
        _empleado.descripcionDepartamento = findDepartamentoById(_empleado.departamentoId).descripcion;
        _empleado.descripcionOficina = findOficinaById(_empleado.oficinaId).descripcion;
        _empleado.descripcionNivel = findNivelById(_empleado.nivelId).descripcion;
        _empleado.descripcionUnidad = findUnidadById(_empleado.unidadId).descripcion;

        _empleados.push(_empleado);
        setEmpleados(_empleados);
        //console.log('addNew, _empleados -->' + JSON.stringify(_empleados));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Empleado Creada', life: 3000 });

        setEmpleadoDialog(false);
        setEmpleado(emptyEmpleado);

        return;
    };

    const editItem = () => {
        let _empleados = [...empleados];
        let _empleado = { ...empleado };

        const index = findIndexById(empleado.empleadoId);
        _empleado.fechaModificacion = formatDate(Date.now());
        _empleado.departamentoNombre = findDepartamentoById(empleado.departamentoId).descripcion;
        _empleado.descripcionOficina = findOficinaById(_empleado.oficinaId).descripcion;
        _empleado.descripcionNivel = findNivelById(_empleado.nivelId).descripcion;
        _empleado.descripcionUnidad = findUnidadById(_empleado.unidadId).descripcion;

        _empleados[index] = _empleado;
        setEmpleados(_empleados);
        //console.log('editItem, _empleados -->' + JSON.stringify(_empleados));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Empleado Actualizada', life: 3000 });

        setEmpleadoDialog(false);
        setEmpleado(emptyEmpleado);

        return;
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _empleados = empleados.filter((val) => val.empleadoId !== response.id);

            setEmpleados(_empleados);
            setDeleteEmpleadoDialog(false);
            setEmpleado(emptyEmpleado);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Empleado Eliminado', life: 3000 });
        }
        else{
            setDeleteEmpleadoDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const saveEmpleado = () => {
        setSubmitted(true);
        setRfcValido(validateRFC(empleado.rfc));
        setCurpValido(validateCURP(empleado.curp));
        setEmailOficialValido(validateEmail(empleado.emailOficial));

        if (empleado.nombre.trim() && empleado.apellidoPaterno.trim() && empleado.apellidoMaterno.trim() && empleado.rfc.trim() && empleado.nivelId && empleado.departamentoId && empleado.celular.trim() && empleado.emailOficial && empleado.unidadId && empleado.oficinaId && emailOficialValido && rfcValido && curpValido) {
            let _empleado = { ...empleado };
            if (empleado.empleadoId) {
                const empleadoService = new EmpleadoService();
                let _empleadoUpdate = { ...emptyEmpleado };
                _empleadoUpdate.empleadoId = empleado.empleadoId;
                _empleadoUpdate.nombre = empleado.nombre;
                _empleadoUpdate.apellidoMaterno = empleado.apellidoMaterno
                _empleadoUpdate.apellidoPaterno = empleado.apellidoPaterno;
                _empleadoUpdate.curp = empleado.curp;
                _empleadoUpdate.rfc = empleado.rfc;
                _empleadoUpdate.nivelId = empleado.nivelId;
                _empleadoUpdate.departamentoId = empleado.departamentoId;
                _empleadoUpdate.direccion = empleado.direccion;
                _empleadoUpdate.Extension = empleado.Extension;
                _empleadoUpdate.celular = empleado.celular;
                _empleadoUpdate.emailPersonal = empleado.emailPersonal;
                _empleadoUpdate.emailOficial = empleado.emailOficial;
                _empleadoUpdate.foto = empleado.foto;
                _empleadoUpdate.unidadId = empleado.unidadId;
                _empleadoUpdate.oficinaId = empleado.oficinaId;
                empleadoService.updateEmpleado(_empleadoUpdate).then(() => editItem());
            } else {
                const empleadoService = new EmpleadoService();
                empleadoService.saveEmpleado(_empleado).then((empleadoId) => addNew(empleadoId));
            }
            
        }
    };

    const editEmpleado = (empleado) => {
        setEmpleado({ ...empleado });
        setEmpleadoDialog(true);
    };

    const confirmDeleteEmpleado = (empleado) => {
        setEmpleado(empleado);
        setDeleteEmpleadoDialog(true);
    };

    const deleteEmpleado = () => {
        const empleadoService = new EmpleadoService();
        empleadoService.deleteEmpleado(empleado.empleadoId).then((response) => deleteItem(response));
    };

    const findIndexById = (empleadoId) => {
        let index = -1;
        if(empleados)
            for (let i = 0; i < empleados.length; i++) {
                if (empleados[i].empleadoId === empleadoId) {
                    index = i;
                    break;
                }
            }
        return index;
    };

    const findDepartamentoById = (departamentoId) => {
        if(departamentos && departamentos.length>0){
            for (let i = 0; i < departamentos.length; i++) 
                if (departamentos[i].id === departamentoId)
                    return departamentos[i];
            return departamentos[0];
        }
    };

    const findOficinaById = (oficinaId) => {
        if(oficinas && oficinas.length>0){
            for (let i = 0; i < oficinas.length; i++) 
                if (oficinas[i].id === oficinaId)
                    return oficinas[i];
            return oficinas[0];
        }
    };

    const findNivelById = (nivelId) => {
        if(niveles && niveles.length>0){
            for (let i = 0; i < niveles.length; i++) 
                if (niveles[i].id === nivelId)
                    return niveles[i];
            return niveles[0];
        }
    };

    const findUnidadById = (unidadId) => {
        if(unidades && unidades.length>0){
            for (let i = 0; i < unidades.length; i++) 
                if (unidades[i].id === unidadId)
                    return unidades[i];
            return unidades[0];
        }
    };

    const onIdChange = (val, name) => {
        let _empleado = { ...empleado };
        _empleado[`${name}`] = val;

        setEmpleado(_empleado);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedEmpleados = () => {
        const empleadoService = new EmpleadoService();
        for (let i = 0; i < selectedEmpleados.length; i++) {
            empleadoService.deleteEmpleado(selectedEmpleados[i].empleadoId);
        }

        let _empleados = empleados.filter((val) => !selectedEmpleados.includes(val));
        setEmpleados(_empleados);
        setDeleteEmpleadosDialog(false);
        setSelectedEmpleados(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Empleados eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _empleado = { ...empleado };
        _empleado[`${name}`] = val;

        if(name==='emailOficial')
            setEmailOficialValido(validateEmail(val));

        if(name==='rfc')
            setRfcValido(validateRFC(val));

        if(name==='curp')
            setCurpValido(validateCURP(val));

        setEmpleado(_empleado);
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validateCURP = (curp) => {
        const curpRegex = /^[A-Z]{4}[0-9]{6}[HM][A-Z]{5}[A-Z0-9]{2}$/;
        return curpRegex.test(curp);
      };

    const validateRFC = (rfc) => {
        const rfcRegex = /^[A-Za-z]{4}\d{6}[A-Za-z0-9]{3}$/;
        return rfcRegex.test(rfc);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const rfcBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rfc</span>
                {rowData.rfc}
            </>
        );
    };

    const nombreCompletoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre Empleado</span>
                {rowData.nombreCompleto}
            </>
        );
    };

    const departamentoDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Departamento</span>
                {rowData.descripcionDepartamento}
            </>
        );
    };

    const oficinaDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Oficina</span>
                {rowData.descripcionOficina}
            </>
        );
    };

    const nivelDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nivel</span>
                {rowData.descripcionNivel}
            </>
        );
    };

    const unidadDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Unidad</span>
                {rowData.descripcionUnidad}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editEmpleado(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteEmpleado(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Empleados</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const empleadoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveEmpleado} />
        </>
    );
    const deleteEmpleadoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEmpleadoDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteEmpleado} />
        </>
    );
    const deleteEmpleadosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEmpleadosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedEmpleados} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={empleados}
                        selection={selectedEmpleados}
                        onSelectionChange={(e) => setSelectedEmpleados(e.value)}
                        dataKey="empleadoId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} empleados"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de empleado."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="nombreCompleto" header="Nombre Empleado" body={nombreCompletoBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="rfc" header="RFC" sortable body={rfcBodyTemplate} headerStyle={{ width: '7%', minWidth: '4rem' }}></Column>
                        <Column field="nivelDescripcion" header="Nivel" body={nivelDescripcionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="departamentoDescripcion" header="Departamento" body={departamentoDescripcionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="oficinaDescripcion" header="Oficina" body={oficinaDescripcionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="unidadDescripcion" header="Unidad" body={unidadDescripcionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Mod." body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '7rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={empleadoDialog} style={{ width: '675px' }} header="Empleado" modal className="p-fluid" footer={empleadoDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="nombre">Nombre(s)</label>
                            <InputText id="nombre" value={empleado.nombre} onChange={(e) => onInputChange(e, 'nombre')} maxLength="100" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.nombre })} />
                            {submitted && !empleado.nombre && <small className="p-invalid">Nombre(s) es requerido.</small>}
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="apellidoPaterno">Apellido Paterno</label>
                                    <InputText id="apellidoPaterno" value={empleado.apellidoPaterno} onChange={(e) => onInputChange(e, 'apellidoPaterno')} maxLength="60" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.apellidoPaterno })} />
                                    {submitted && !empleado.apellidoPaterno && <small className="p-invalid">Apellido Paterno es requerido.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="apellidoMaterno">Apellido Materno</label>
                                    <InputText id="apellidoMaterno" value={empleado.apellidoMaterno} onChange={(e) => onInputChange(e, 'apellidoMaterno')} maxLength="60" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.apellidoMaterno })} />
                                    {submitted && !empleado.apellidoMaterno && <small className="p-invalid">Apellido Materno es requerido.</small>}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="rfc">RFC</label>
                                    <InputText id="rfc" value={empleado.rfc} onChange={(e) => onInputChange(e, 'rfc')} maxLength="13" required autoFocus className={classNames({ 'p-invalid': submitted && (!rfcValido || !empleado.rfc)})} />
                                    {submitted && !empleado.rfc && <small className="p-invalid">RFC es requerido.</small>}
                                    {submitted && !rfcValido && <small className="p-invalid">RFC invalido.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="curp">CURP</label>
                                    <InputText id="curp" value={empleado.curp} onChange={(e) => onInputChange(e, 'curp')} maxLength="18" required autoFocus className={classNames({ 'p-invalid': submitted && (!curpValido || !empleado.curp) })} />
                                    {submitted && !empleado.curp && <small className="p-invalid">CURP es requerido.</small>}
                                    {submitted && !curpValido && <small className="p-invalid">CURP invalido.</small>}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="nivel">Nivel</label>
                                    <Dropdown id="nivel" options={niveles} value={findNivelById(empleado.nivelId)}  onChange={(e) => onIdChange(e.value.id, 'nivelId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.nivelId })} ></Dropdown>
                                    {submitted && !empleado.nivelId && <small className="p-invalid">Nivel es requerido.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="departamento">Departamento</label>
                                    <Dropdown id="departamento" options={departamentos} value={findDepartamentoById(empleado.departamentoId)}  onChange={(e) => onIdChange(e.value.id, 'departamentoId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.departamentoId })} ></Dropdown>
                                    {submitted && !empleado.departamentoId && <small className="p-invalid">Departamento es requerido.</small>}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="direccion">Dirección</label>
                            <InputTextarea id="direccion" value={empleado.direccion} onChange={(e) => onInputChange(e, 'direccion')} maxLength="255" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !empleado.direccion })}/>
                            {submitted && !empleado.direccion && <small className="p-invalid">Dirección es requerida.</small>}
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="Extension">Extensión</label>
                                    <InputText id="Extension" mask='999999' value={empleado.Extension} onChange={(e) => onInputChange(e, 'Extension')} maxLength={8}  />
                                   
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="celular">Celular</label>
                                    <InputMask id="celular"  mask='99-99999999' value={empleado.celular} onChange={(e) => onInputChange(e, 'celular')}   />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                             
                                <div className="field col-6">
                                    <label htmlFor="emailOficial">Email Oficina</label>
                                    <InputText id="emailOficial" value={empleado.emailOficial} onChange={(e) => onInputChange(e, 'emailOficial')} maxLength="50" required autoFocus className={classNames({ 'p-invalid': submitted && (!empleado.emailOficial || !emailOficialValido)})} />
                                    {submitted && !empleado.emailOficial && <small className="p-invalid">E-mail Oficina es requerido.</small>}
                                    {submitted && !emailOficialValido && <small className="p-invalid">E-mail Oficina invalido.</small>}
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="unidadId">Unidad</label>
                                    <Dropdown id="unidadId" options={unidades} value={findUnidadById(empleado.unidadId)}  onChange={(e) => onIdChange(e.value.id, 'unidadId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.unidadId })} ></Dropdown>
                                    {submitted && !empleado.unidadId && <small className="p-invalid">Unidad es requerido.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="oficinaId">Oficina</label>
                                    <Dropdown id="oficinaId" options={oficinas} value={findOficinaById(empleado.oficinaId)}  onChange={(e) => onIdChange(e.value.id, 'oficinaId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !empleado.oficinaId })} ></Dropdown>
                                    {submitted && !empleado.oficinaId && <small className="p-invalid">Oficina es requerido.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEmpleadoDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEmpleadoDialogFooter} onHide={hideDeleteEmpleadoDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {empleado && (
                                <span>
                                    Estas seguro que deseas borrar el empleado <b>{empleado.departamentoNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteEmpleadosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteEmpleadosDialogFooter} onHide={hideDeleteEmpleadosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {empleado && <span>Estas seguro que deseas borrar los empleados seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudEmpleado;
