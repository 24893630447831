import axios from 'axios';
import packageJson from '../../../package.json';

export default class OficinaService {
    
    getOficinasJson() {
        return axios.get('assets/demo/data/tickets/oficinas.json').then((res) => res.data.data);
    }

    getOficinas() {
        return axios.get(packageJson.service.url + '/catalog/offices/get').then((res) => res.data.response);
    }

    saveOficina(request) {
        return axios.post(packageJson.service.url + '/catalog/offices/post', request).then((res) => res.data.response.oficinaId);
    }

    updateOficina(request) {
        return axios.put(packageJson.service.url + '/catalog/offices/put', request).then((res) => res.data);
    }

    deleteOficina(id) {
        return axios.delete(packageJson.service.url + '/catalog/offices/delete/'+id).then((res) => res.data);
    }
}
