import axios from 'axios';
import packageJson from '../../../package.json';

export default class UsuarioService {
    
    getUsuariosJson() {
        return axios.get('assets/demo/data/tickets/usuarios.json').then((res) => res.data.data);
    }

    getUsuarios() {
        return axios.get(packageJson.service.url + '/catalog/users/get').then((res) => res.data.response);
    }

    saveUsuario(request) {
        return axios.post(packageJson.service.url + '/catalog/users/post', request).then((res) => res.data.response.usuarioId);
    }

    updateUsuario(request) {
        return axios.put(packageJson.service.url + '/catalog/users/put', request).then((res) => res.data);
    }

    deleteUsuario(id) {
        return axios.delete(packageJson.service.url + '/catalog/users/delete/'+id).then((res) => res.data);
    }
}
