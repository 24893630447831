import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import OficinaService from '../../service/tickets/OficinaService';
import CatalogoService from '../../service/tickets/CatalogoService';

const CrudOficina = () => {
    let emptyOficina = {
        oficinaId: 0,
        descripcion: '',
        detalles: '',
        edificioId: 0,
        descripcionEdificio: '',
        pisoId: 0,
        descripcionPiso: '',
        numero: ''
        
    };

    const [oficinas, setOficinas] = useState(null);
    const [edificios, setEdificios] = useState(null);
    const [pisos, setPisos] = useState(null);
    const [oficinaDialog, setOficinaDialog] = useState(false);
    const [deleteOficinaDialog, setDeleteOficinaDialog] = useState(false);
    const [deleteOficinasDialog, setDeleteOficinasDialog] = useState(false);
    const [oficina, setOficina] = useState(emptyOficina);
    const [selectedOficinas, setSelectedOficinas] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const oficinaService = new OficinaService();
        oficinaService.getOficinas().then((data) => setOficinas(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catedificio').then((data) => setEdificios(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catpiso').then((data) => setPisos(data));
    }, []);

    const formatDate = (value) => {
        return (new Date(value)).toLocaleString('es-MX', {day: '2-digit',month: '2-digit',year: 'numeric'});
    };

    const openNew = () => {
        setOficina(emptyOficina);
        setSubmitted(false);
        setOficinaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setOficinaDialog(false);
    };

    const hideDeleteOficinaDialog = () => {
        setDeleteOficinaDialog(false);
    };

    const hideDeleteOficinasDialog = () => {
        setDeleteOficinasDialog(false);
    };

    const addNew = (oficinaId) => {
        let _oficinas = [...oficinas];
        let _oficina = { ...oficina };

        _oficina.oficinaId = oficinaId;
        _oficina.fechaRegistro = formatDate(Date.now());
        _oficina.fechaModificacion = formatDate(Date.now());
        _oficina.descripcionEdificio = findEdificioById(_oficina.edificioId).descripcion;
        _oficina.descripcionPiso = findPisoById(_oficina.pisoId).descripcion;

        _oficinas.push(_oficina);
        setOficinas(_oficinas);
        //console.log('addNew, _oficinas -->' + JSON.stringify(_oficinas));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Oficina Creada', life: 3000 });

        setOficinaDialog(false);
        setOficina(emptyOficina);

        return;
    };

    const editItem = () => {
        let _oficinas = [...oficinas];
        let _oficina = { ...oficina };

        const index = findIndexById(oficina.oficinaId);
        _oficina.fechaModificacion = formatDate(Date.now());
        _oficina.edificioNombre = findEdificioById(oficina.edificioId).descripcion;
        _oficina.pisoDescripcion = findEdificioById(_oficina.pisoId).descripcion;

        _oficinas[index] = _oficina;
        setOficinas(_oficinas);
        //console.log('editItem, _oficinas -->' + JSON.stringify(_oficinas));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Oficina Actualizada', life: 3000 });

        setOficinaDialog(false);
        setOficina(emptyOficina);

        return;
    };

    const saveOficina = () => {
        setSubmitted(true);

        if (oficina.edificioId && oficina.pisoId && oficina.detalles.trim() && oficina.descripcion.trim()) {
            let _oficina = { ...oficina };
            if (oficina.oficinaId) {
                const oficinaService = new OficinaService();
                let _oficinaUpdate = { ...emptyOficina };
                _oficinaUpdate.oficinaId = oficina.oficinaId;
                _oficinaUpdate.descripcion = oficina.descripcion;
                _oficinaUpdate.detalles = oficina.detalles;
                _oficinaUpdate.edificioId = oficina.edificioId;
                _oficinaUpdate.pisoId = oficina.pisoId;
                
                oficinaService.updateOficina(_oficinaUpdate).then(() => editItem());
            } else {
                const oficinaService = new OficinaService();
                oficinaService.saveOficina(_oficina).then((oficinaId) => addNew(oficinaId));
            }
            
        }
    };

    const editOficina = (oficina) => {
        setOficina({ ...oficina });
        setOficinaDialog(true);
    };

    const confirmDeleteOficina = (oficina) => {
        setOficina(oficina);
        setDeleteOficinaDialog(true);
    };

    const deleteOficina = () => {
        const oficinaService = new OficinaService();
        oficinaService.deleteOficina(oficina.oficinaId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _oficinas = oficinas.filter((val) => val.oficinaId !== response.id);

            setOficinas(_oficinas);
            setDeleteOficinaDialog(false);
            setOficina(emptyOficina);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Oficina Eliminado', life: 3000 });
        }
        else{
            setDeleteOficinaDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (oficinaId) => {
        let index = -1;
        if(oficinas)
            for (let i = 0; i < oficinas.length; i++) {
                if (oficinas[i].oficinaId === oficinaId) {
                    index = i;
                    break;
                }
            }
        return index;
    };

    const findEdificioById = (edificioId) => {
        if(edificios && edificios.length>0){
            for (let i = 0; i < edificios.length; i++) 
                if (edificios[i].id === edificioId)
                    return edificios[i];
            return edificios[0];
        }
    };

    const findPisoById = (pisoId) => {
        if(pisos && pisos.length>0){
            for (let i = 0; i < pisos.length; i++) 
                if (pisos[i].id === pisoId)
                    return pisos[i];
            return pisos[0];
        }
    };

    const onIdChange = (val, name) => {
        let _oficina = { ...oficina };
        _oficina[`${name}`] = val;

        setOficina(_oficina);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedOficinas = () => {
        const oficinaService = new OficinaService();
        for (let i = 0; i < selectedOficinas.length; i++) {
            oficinaService.deleteOficina(selectedOficinas[i].oficinaId);
        }

        let _oficinas = oficinas.filter((val) => !selectedOficinas.includes(val));
        setOficinas(_oficinas);
        setDeleteOficinasDialog(false);
        setSelectedOficinas(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Oficinas eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _oficina = { ...oficina };
        _oficina[`${name}`] = val;

        setOficina(_oficina);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const detallesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Detalles</span>
                {rowData.detalles}
            </>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const edificioDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Edificio</span>
                {rowData.descripcionEdificio}
            </>
        );
    };

    const pisoDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Piso</span>
                {rowData.descripcionPiso}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editOficina(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteOficina(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Oficinas</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const oficinaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveOficina} />
        </>
    );
    const deleteOficinaDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOficinaDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteOficina} />
        </>
    );
    const deleteOficinasDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOficinasDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedOficinas} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={oficinas}
                        selection={selectedOficinas}
                        onSelectionChange={(e) => setSelectedOficinas(e.value)}
                        dataKey="oficinaId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} oficinas"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de oficina."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="detalles" header="Detalles" sortable body={detallesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="edificioDescripcion" header="Edificio" body={edificioDescripcionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="pisoDescripcion" header="Piso" body={pisoDescripcionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={oficinaDialog} style={{ width: '450px' }} header="Oficina" modal className="p-fluid" footer={oficinaDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="descripcion">Descripción</label>
                            <InputTextarea id="description" value={oficina.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !oficina.descripcion })}/>
                            {submitted && !oficina.descripcion && <small className="p-invalid">Descripcion es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="detalles">Detalles</label>
                            <InputTextarea id="detalles" value={oficina.detalles} onChange={(e) => onInputChange(e, 'detalles')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !oficina.detalles })}/>
                            {submitted && !oficina.detalles && <small className="p-invalid">Detalles es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="edificio">Edificio</label>
                            <Dropdown id="edificio" options={edificios} value={findEdificioById(oficina.edificioId)}  onChange={(e) => onIdChange(e.value.id, 'edificioId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !oficina.edificioId })} ></Dropdown>
                            {submitted && !oficina.edificioId && <small className="p-invalid">Edificio es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="piso">Piso</label>
                            <Dropdown id="piso" options={pisos} value={findPisoById(oficina.pisoId)}  onChange={(e) => onIdChange(e.value.id, 'pisoId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !oficina.pisoId })} ></Dropdown>
                            {submitted && !oficina.pisoId && <small className="p-invalid">Piso es requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOficinaDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOficinaDialogFooter} onHide={hideDeleteOficinaDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {oficina && (
                                <span>
                                    Estas seguro que deseas borrar el oficina <b>{oficina.edificioNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteOficinasDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOficinasDialogFooter} onHide={hideDeleteOficinasDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {oficina && <span>Estas seguro que deseas borrar los oficinas seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudOficina;
