import axios from 'axios';
import packageJson from '../../../package.json';

export default class NivelService {
    
    getNIvelesJson() {
        return axios.get('assets/demo/data/tickets/nivels.json').then((res) => res.data.data);
    }

    getNiveles() {
        return axios.get(packageJson.service.url + '/catalog/nivels/get').then((res) => res.data.response);
    }

    saveNivel(request) {
        return axios.post(packageJson.service.url + '/catalog/nivels/post', request).then((res) => res.data.response.nivelId);
    }

    updateNivel(request) {
        return axios.put(packageJson.service.url + '/catalog/nivels/put', request).then((res) => res.data);
    }

    deleteNivel(id) {
        return axios.delete(packageJson.service.url + '/catalog/nivels/delete/'+id).then((res) => res.data);
    }
}