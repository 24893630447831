import React from 'react';

export const Error = (mensajeerror) => {
    return (
        <div className="exception-body error">
            <div className="exception-panel"></div>

            <div className="exception-content">
                <img src="assets/layout/images/logo-black.png" alt="babylon-layout" />
                <h1>
                    <span className="exception-name"> ERROR</span> OCURRIDO
                </h1>
                <p>La contraseña ó el usuario son incorrectos</p>
                <a href="#/Login">Regresar a al logeo</a>
            </div>
        </div>
    );
};
