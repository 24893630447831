import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import ActividadService from '../../service/tickets/ActividadService';
import CatalogoService from '../../service/tickets/CatalogoService';
import { format } from 'date-fns';

const CrudActividad = () => {
    let emptyActividad = {
        actividadId: 0,
        nombre: '',
        descripcion: '',
        alcance: '',
        tiempoEstimado: '',
        dificultad: '',
        responsable: '',
        tareaId: 0
    };

    const [actividades, setActividades] = useState(null);
    const [tareas, setTareas] = useState(null);
    const [actividadDialog, setActividadDialog] = useState(false);
    const [deleteActividadDialog, setDeleteActividadDialog] = useState(false);
    const [deleteActividadesDialog, setDeleteActividadesDialog] = useState(false);
    const [actividad, setActividad] = useState(emptyActividad);
    const [selectedActividades, setSelectedActividades] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const actividadService = new ActividadService();
        actividadService.getActividades().then((data) => setActividades(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_cattarea').then((data) => setTareas(data));
    }, []);

    const formatDate = (value) => {
        return format(value, 'yyyy-MM-dd');
    };

    const openNew = () => {
        setActividad(emptyActividad);
        setSubmitted(false);
        setActividadDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setActividadDialog(false);
    };

    const hideDeleteActividadDialog = () => {
        setDeleteActividadDialog(false);
    };

    const hideDeleteActividadesDialog = () => {
        setDeleteActividadesDialog(false);
    };

    const saveActividad = () => {
        setSubmitted(true);

        if (actividad.nombre.trim() && actividad.descripcion.trim() && actividad.alcance.trim() && actividad.tiempoEstimado.trim() && actividad.dificultad.trim() && actividad.responsable.trim() && actividad.tareaId) {
            let _actividad = { ...actividad };
            console.log('_actividad =>' + JSON.stringify(_actividad));
            if (actividad.actividadId) {
                const actividadService = new ActividadService();
                actividadService.updateActividad(_actividad).then(() => editItem());
            } else {
                const actividadService = new ActividadService();
                actividadService.saveActividad(_actividad).then((actividadId) => addNew(actividadId));
            }
            
        }
    };

    const addNew = (actividadId) => {
        let _actividades = [...actividades];
        let _actividad = { ...actividad };

        _actividad.actividadId = actividadId;
        _actividad.fechaRegistro = formatDate(Date.now());
        _actividad.fechaModificacion = formatDate(Date.now());
        _actividad.descripcionTarea = findTareaById(_actividad.tareaId).descripcion;

        _actividades.push(_actividad);
        setActividades(_actividades);
        //console.log('addNew, _actividads -->' + JSON.stringify(_actividads));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Actividad Creada', life: 3000 });

        setActividadDialog(false);
        setActividad(emptyActividad);

        return;
    };

    const editItem = () => {
        let _actividades = [...actividades];
        let _actividad = { ...actividad };

        const index = findIndexById(actividad.actividadId);
        _actividad.fechaModificacion = formatDate(Date.now());
        _actividad.descripcionTarea = findTareaById(_actividad.tareaId).descripcion;

        _actividades[index] = _actividad;
        setActividades(_actividades);
        //console.log('editItem, _actividads -->' + JSON.stringify(_actividads));
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Actividad Actualizada', life: 3000 });

        setActividadDialog(false);
        setActividad(emptyActividad);

        return;
    };

    const editActividad = (actividad) => {
        setActividad({ ...actividad });
        setActividadDialog(true);
    };

    const confirmDeleteActividad = (actividad) => {
        setActividad(actividad);
        setDeleteActividadDialog(true);
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _actividades = actividades.filter((val) => val.actividadId !== response.id);

            setActividades(_actividades);
            setDeleteActividadDialog(false);
            setActividad(emptyActividad);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Actividad Eliminada', life: 3000 });
        }
        else{
            setDeleteActividadDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const deleteActividad = () => {
        const actividadService = new ActividadService();
        actividadService.deleteActividad(actividad.actividadId).then((response) => deleteItem(response));
    };

    const findIndexById = (actividadId) => {
        let index = -1;
        for (let i = 0; i < actividades.length; i++) {
            if (actividades[i].actividadId === actividadId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const findTareaById = (tareaId) => {
        if(tareas && tareas.length>0){
            for (let i = 0; i < tareas.length; i++) 
                if (tareas[i].id === tareaId)
                    return tareas[i];
            return tareas[0];
        }
    };

    const onIdChange = (val, name) => {
        let _actividad = { ...actividad };
        _actividad[`${name}`] = val;

        setActividad(_actividad);
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedActividades = () => {
        const actividadService = new ActividadService();
        for (let i = 0; i < selectedActividades.length; i++) {
            actividadService.deleteActividad(selectedActividades[i].actividadId);
        }

        let _actividades = actividades.filter((val) => !selectedActividades.includes(val));
        setActividades(_actividades);
        setDeleteActividadesDialog(false);
        setSelectedActividades(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Actividades eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _actividad = { ...actividad };
        _actividad[`${name}`] = val;

        setActividad(_actividad);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.descripcion}
            </>
        );
    };

    const descripcionTareaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción Tarea</span>
                {rowData.descripcionTarea}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const alcanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Alcance</span>
                {rowData.alcance}
            </>
        );
    };

    const tiempoEstimadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tiempo Estimado</span>
                {rowData.tiempoEstimado}
            </>
        );
    };

    const dificultadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dificultad</span>
                {rowData.dificultad}
            </>
        );
    };

    const responsableBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Responsable</span>
                {rowData.responsable}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editActividad(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteActividad(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Actividades</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const actividadDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveActividad} />
        </>
    );
    const deleteActividadDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActividadDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteActividad} />
        </>
    );
    const deleteActividadesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteActividadesDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedActividades} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={actividades}
                        selection={selectedActividades}
                        onSelectionChange={(e) => setSelectedActividades(e.value)}
                        dataKey="actividadId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} actividades"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de actividad."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="nombre" header="Nombre" body={nombreBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="descripcion" header="Descripción" body={descripcionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="alcance" header="Alcance" body={alcanceBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="tiempoEstimado" header="Tiempo Estimado" body={tiempoEstimadoBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="dificultad" header="Dificultad" body={dificultadBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="responsable" header="Responsable" body={responsableBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="descripcionTarea" header="Tarea" body={descripcionTareaBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Alta" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Mod." body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={actividadDialog} style={{ width: '450px' }} header="Actividad" modal className="p-fluid" footer={actividadDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={actividad.nombre} onChange={(e) => onInputChange(e, 'nombre')} maxLength="100" required autoFocus className={classNames({ 'p-invalid': submitted && !actividad.nombre })} />
                            {submitted && !actividad.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="descripcion">Descripcion</label>
                            <InputTextarea id="description" value={actividad.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} maxLength="100" required rows={2} cols={20} autoFocus className={classNames({ 'p-invalid': submitted && !actividad.descripcion })}/>
                            {submitted && !actividad.descripcion && <small className="p-invalid">Descripcion es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="alcance">Alcance</label>
                            <InputText id="alcance" value={actividad.alcance} onChange={(e) => onInputChange(e, 'alcance')} maxLength="100" required autoFocus className={classNames({ 'p-invalid': submitted && !actividad.alcance })} />
                            {submitted && !actividad.alcance && <small className="p-invalid">Alcance es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="tiempoEstimado">Tiempo Estimado</label>
                            <InputMask id="tiempoEstimado" mask='99:99'  value={actividad.tiempoEstimado} onChange={(e) => onInputChange(e, 'tiempoEstimado')} required className={classNames({ 'p-invalid': submitted && !actividad.tiempoEstimado })} />
                            {submitted && !actividad.tiempoEstimado && <small className="p-invalid">Tiempo Estimado es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="dificultad">Dificultad</label>
                            <InputText id="dificultad" value={actividad.dificultad} onChange={(e) => onInputChange(e, 'dificultad')} maxLength="100" required autoFocus className={classNames({ 'p-invalid': submitted && !actividad.dificultad })} />
                            {submitted && !actividad.dificultad && <small className="p-invalid">Dificultad es requerida.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="responsable">Responsable</label>
                            <InputText id="responsable" value={actividad.responsable} onChange={(e) => onInputChange(e, 'responsable')} maxLength="100" required autoFocus className={classNames({ 'p-invalid': submitted && !actividad.responsable })} />
                            {submitted && !actividad.responsable && <small className="p-invalid">Responsable es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="tareaId">Tarea</label>
                            <Dropdown id="tareaId" options={tareas} value={findTareaById(actividad.tareaId)}  onChange={(e) => onIdChange(e.value.id, 'tareaId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !actividad.tareaId })} ></Dropdown>
                            {submitted && !actividad.tareaIdId && <small className="p-invalid">Tarea es requerida.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActividadDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActividadDialogFooter} onHide={hideDeleteActividadDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {actividad && (
                                <span>
                                    Estas seguro que deseas borrar el actividad <b>{actividad.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteActividadesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteActividadesDialogFooter} onHide={hideDeleteActividadesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {actividad && <span>Estas seguro que deseas borrar los actividades seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudActividad;
