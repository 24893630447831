import React, { useState } from 'react';
import { Image } from 'primereact/image';
import { InputTextarea } from 'primereact/inputtextarea';
import Tesseract from 'tesseract.js';

function OcrComponent() {
  const [imageSrc, setImageSrc] = useState('');
  const [recognizedText, setRecognizedText] = useState('');
  const [jsonResult, setJsonResult] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (event) => {
    setLoading(true);
    setRecognizedText('');
    setJsonResult('');
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);

      // Perform OCR using Tesseract.js
      const { data: { text } } = await Tesseract.recognize(file, 'eng', { logger: m => console.log(m) });
      setRecognizedText(text);

      // Convert recognized text to JSON
      const json = convertTextToJson(text);
      setJsonResult(json);

      setLoading(false);
    }
  };

  const convertTextToJson = (text) => {
    // Basic conversion to JSON
    const lines = text.split('\n');
    const json = {};
    lines.forEach((line, index) => {
      json[`line${index + 1}`] = line.trim();
    });
    return JSON.stringify(json, null, 2);
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleFileUpload} />
     
      <div className="flex justify-content-center">
          <Image src={imageSrc} alt="galleria" width={250} preview />
      </div>
      <div>
        <h2>Recognized Text:</h2>
        {loading && <p>Loading...</p>}
        <p>{recognizedText}</p>
        <h2>JSON Result:</h2>
        {loading && <p>Loading...</p>}
        <pre>{jsonResult}</pre>
      </div>
    </div>
  );
}

export default OcrComponent;
