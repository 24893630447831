import axios from 'axios';
import packageJson from '../../../package.json';

export default class CatalogoService {
    
    getCatalogos(catalogo, empleadoId) {
        if(empleadoId)
            return axios.get(packageJson.service.url + '/catalog/catalogs/get/'+catalogo+'/'+empleadoId).then((res) => res.data.response);
        else
            return axios.get(packageJson.service.url + '/catalog/catalogs/get/'+catalogo+'/0').then((res) => res.data.response);
    }   
}
