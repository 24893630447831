import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import UsuarioService from '../../service/tickets/UsuarioService';
import CatalogoService from '../../service/tickets/CatalogoService';
import { SelectButton } from 'primereact/selectbutton';
import { format } from 'date-fns';

const CrudUsuario = () => {
    let emptyUsuario = {
        usuarioId: 0,
        username: '',
        contrasenia: '',
        confirmarContrasenia: '',
        perfilId: 0,
        empleadoId: 0,
        perfilDescripcion: '',
        estatusId: 0,
        estatusDescripcion: ''
    };

    const [usuarios, setUsuarios] = useState(null);
    const [estatus, setEstatus] = useState(null);
    const [perfiles, setPerfiles] = useState(null);
    const [empleados, setEmpleados] = useState(null);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [deleteUsuariosDialog, setDeleteUsuariosDialog] = useState(false);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [selectedUsuarios, setSelectedUsuarios] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const usuarioService = new UsuarioService();
        usuarioService.getUsuarios().then((data) => setUsuarios(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catestatususuario').then((data) => setEstatus(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catperfil').then((data) => setPerfiles(data));
    }, []);

    useEffect(() => {
        const catalogoService = new CatalogoService();
        catalogoService.getCatalogos('_catempleado').then((data) => setEmpleados(data));
    }, []);

    const formatDate = (value) => {
        return format(value, 'yyyy-MM-dd HH:mm');
    };

    const openNew = () => {
        setUsuario(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    };

    const hideDeleteUsuarioDialog = () => {
        setDeleteUsuarioDialog(false);
    };

    const hideDeleteUsuariosDialog = () => {
        setDeleteUsuariosDialog(false);
    };

    const saveUsuario = () => {
        setSubmitted(true);

        if (usuario.username.trim() && usuario.contrasenia.trim() && usuario.confirmarContrasenia.trim() && usuario.empleadoId && usuario.estatusId && usuario.perfilId && (usuario.contrasenia === usuario.confirmarContrasenia)) {
            let _usuarios = [...usuarios];
            let _usuario = { ...usuario };
            if (usuario.usuarioId) {
                const index = findIndexById(usuario.usuarioId);

                const usuarioService = new UsuarioService();
                usuarioService.updateUsuario(_usuario);

                _usuario.fechaModificacion = Date.now();
                _usuario.perfilDescripcion = findPerfilById(usuario.perfilId).descripcion;
                _usuario.estatusDescripcion = findEstatusById(usuario.estatusId).descripcion;

                _usuarios[index] = _usuario;
                setUsuarios(_usuarios);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Actualizado', life: 3000 });
            } else {
                //const usuarioService = new UsuarioService();
                //_usuario.usuarioId = usuarioService.saveUsuario(_usuario);
                const usuarioService = new UsuarioService();
                usuarioService.saveUsuario(_usuario).then((usuarioId) => setUsuarioId(usuarioId));

                _usuario.fechaRegistro = Date.now();
                _usuario.fechaModificacion = Date.now();
                _usuario.perfilDescripcion = findPerfilById(usuario.perfilId).descripcion;
                _usuario.estatusDescripcion = findEstatusById(usuario.estatusId).descripcion;

                _usuarios.push(_usuario);
                setUsuarios(_usuarios);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Creado', life: 3000 });
            }
            setUsuarioDialog(false);
            setUsuario(emptyUsuario);
        }
    };

    const editUsuario = (usuario) => {
        setUsuario({ ...usuario });
        setUsuarioDialog(true);
    };

    const confirmDeleteUsuario = (usuario) => {
        setUsuario(usuario);
        setDeleteUsuarioDialog(true);
    };

    const deleteUsuario = () => {
        const usuarioService = new UsuarioService();
        usuarioService.deleteUsuario(usuario.usuarioId).then((response) => deleteItem(response));
    };

    const deleteItem = (response) => {
        if(response.message === 'ok')
        {
            let _usuarios = usuarios.filter((val) => val.usuarioId !== response.id);

            setUsuarios(_usuarios);
            setDeleteUsuarioDialog(false);
            setUsuario(emptyUsuario);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuario Eliminado', life: 3000 });
        }
        else{
            setDeleteUsuarioDialog(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.message, life: 3000 });
        }
        
        return;
    };

    const findIndexById = (usuarioId) => {
        let index = -1;
        for (let i = 0; i < usuarios.length; i++) {
            if (usuarios[i].usuarioId === usuarioId) {
                index = i;
                break;
            }
        }
        return index;
    };

    const findPerfilById = (perfilId) => {
        if(perfiles && perfiles.length>0){
            for (let i = 0; i < perfiles.length; i++) 
                if (perfiles[i].id === perfilId)
                    return perfiles[i];
            return perfiles[0];
        }
    };

    const findEmpleadoById = (empleadoId) => {
        if(empleados && empleados.length>0){
            for (let i = 0; i < empleados.length; i++) 
                if (empleados[i].id === empleadoId)
                    return empleados[i];
            return empleados[0];
        }
    };

    const findEstatusById = (estatusId) => {
        if(estatus && estatus.length>0){
            for (let i = 0; i < estatus.length; i++) {
                if (estatus[i].id === estatusId)
                    return estatus[i];
            }
            return estatus[0];
        }
    };

    const setUsuarioId = (usuarioId) => {
        let index = usuarios.length-1;
        usuarios[index].usuarioId = usuarioId;

        return;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const deleteSelectedUsuarios = () => {
        const usuarioService = new UsuarioService();
        for (let i = 0; i < selectedUsuarios.length; i++) {
            usuarioService.deleteUsuario(selectedUsuarios[i].usuarioId);
        }

        let _usuarios = usuarios.filter((val) => !selectedUsuarios.includes(val));
        setUsuarios(_usuarios);
        setDeleteUsuariosDialog(false);
        setSelectedUsuarios(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Usuarios eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    };

    const onIdChange = (val, name) => {
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;

        setUsuario(_usuario);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Usuario</span>
                {rowData.username}
            </>
        );
    };

    const perfilDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Perfil</span>
                {rowData.perfilDescripcion}
            </>
        );
    };

    const empleadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Empleado</span>
                {rowData.empleado}
            </>
        );
    };

    const estatusDescripcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.estatusDescripcion}
            </>
        );
    };

    const fechaRegistroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Registro</span>
                {formatDate(rowData.fechaRegistro)}
            </>
        );
    };

    const fechaModificacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Actualización</span>
                {formatDate(rowData.fechaModificacion)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteUsuario(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Catálogo Usuarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveUsuario} />
        </>
    );
    const deleteUsuarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuarioDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteUsuario} />
        </>
    );
    const deleteUsuariosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuariosDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsuarios} />
        </>
    );

    return (
        <div className="grid crud-ticket">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={usuarios}
                        selection={selectedUsuarios}
                        onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                        dataKey="usuarioId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} usuarios"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron registros de usuario."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="username" header="Usuario" body={usernameBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="perfilDescripcion" header="Perfil" body={perfilDescripcionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="empleado" header="Empleado" body={empleadoBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="estatusDescripcion" header="Estatus" body={estatusDescripcionBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="fechaRegistro" header="Fecha Registro" body={fechaRegistroBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column field="fechaModificacion" header="Fecha Actualizacion" body={fechaModificacionBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '7rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={usuarioDialog} style={{ width: '550px' }} header="Usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="username">Usuario</label>
                            <InputText id="username" value={usuario.username} onChange={(e) => onInputChange(e, 'username')} maxLength="50" required autoFocus className={classNames({ 'p-invalid': submitted && (!usuario.username || usuario.username.length < 8) })} />
                            {submitted && !usuario.username && <small className="p-invalid">Usuario es requerido.</small>}
                            {submitted && usuario.username.length < 8 && <small className="p-invalid">Usuario deber ser mayor a 8 caracteres.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contrasenia">Contraseña</label>
                            <Password id="contrasenia" type="password" value={usuario.contrasenia} onChange={(e) => onInputChange(e, 'contrasenia')} maxLength="30" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.contrasenia })} />
                            {submitted && !usuario.contrasenia && <small className="p-invalid">Contrasenia es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="confirmarContrasenia">Confirmar Contraseña</label>
                            <Password id="confirmarContrasenia" type="password" value={usuario.confirmarContrasenia} onChange={(e) => onInputChange(e, 'confirmarContrasenia')} maxLength="30" required autoFocus className={classNames({ 'p-invalid': submitted && (!usuario.confirmarContrasenia || usuario.contrasenia !== usuario.confirmarContrasenia) })} />
                            {submitted && !usuario.confirmarContrasenia && <small className="p-invalid">Confirmar Contrasenia es requerido.</small>}
                            {submitted && usuario.confirmarContrasenia !== usuario.contrasenia  && <small className="p-invalid">La contraseña es diferente.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="perfil">Perfil</label>
                            <Dropdown id="perfil" options={perfiles} value={findPerfilById(usuario.perfilId)}  onChange={(e) => onIdChange(e.value.id, 'perfilId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.perfilId })} ></Dropdown>
                            {submitted && !usuario.perfilId && <small className="p-invalid">Perfil es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="empleado">Empleado</label>
                            <Dropdown id="empleado" options={empleados} value={findEmpleadoById(usuario.empleadoId)}  onChange={(e) => onIdChange(e.value.id, 'empleadoId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.empleadoId })} ></Dropdown>
                            {submitted && !usuario.empleadoId && <small className="p-invalid">Empleado es requerido.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="estatusId">Estatus</label>
                            <SelectButton id="estatusId" options={estatus} value={findEstatusById(usuario.estatusId)}  onChange={(e) => onIdChange(e.value.id, 'estatusId')}  optionLabel="descripcion" placeholder="Seleccione una opción" required autoFocus className={classNames({ 'p-invalid': submitted && !usuario.estatusId })} ></SelectButton>
                            {submitted && !usuario.estatusId && <small className="p-invalid">Estatus es requerido.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsuarioDialogFooter} onHide={hideDeleteUsuarioDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && (
                                <span>
                                    Estas seguro que deseas borrar el usuario <b>{usuario.titularNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsuariosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsuariosDialogFooter} onHide={hideDeleteUsuariosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Estas seguro que deseas borrar los usuarios seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CrudUsuario;
