import axios from 'axios';
import packageJson from '../../../package.json';
export default class ActividadService {
    
    getActividadesJson() {
        return axios.get('assets/demo/data/tickets/actividades.json').then((res) => res.data.data);
    }

    getActividades() {
        return axios.get(packageJson.service.url + '/catalog/activities/get').then((res) => res.data.response);
    }

    saveActividad(request) {
        return axios.post(packageJson.service.url + '/catalog/activities/post', request).then((res) => res.data.response.actividadId);
    }

    updateActividad(request) {
        return axios.put(packageJson.service.url + '/catalog/activities/put', request).then((res) => res.data);
    }

    deleteActividad(id) {
        return axios.delete(packageJson.service.url + '/catalog/activities/delete/'+id).then((res) => res.data);
    }
}
