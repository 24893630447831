import axios from 'axios';
import packageJson from '../../../package.json';
//import axiosInstance from './axiosInstance';

export default class ScannerService {

    WebCorsEnable(enable) {
        let _enable = 'ON'
        if(enable)
            _enable = 'ON'
        else
            _enable = 'OFF'
        return axios.post(packageJson.service.urlScanner + '/api/config/ApplyChanges', 'WEB_CORS_ENABLE='+_enable).then((res) => res.data).catch(err => err);
    }

    getScannerStatus() {
        return axios.get(packageJson.service.urlScanner + '/api/scanner/status').then((res) => res.data).catch(err => err);
    }

    startSession() {
        return axios.post(packageJson.service.urlScanner + '/api/session').then((res) => res.data).catch(err => err);
    }

    startScanning(sessionId) {
        return axios.post(packageJson.service.urlScanner + '/api/session/StartScan?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    getSessionStatus(sessionId) {
        return axios.get(packageJson.service.urlScanner + '/api/session?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    getSessionConfiguration(sessionId) {
        return axios.get(packageJson.service.urlScanner + '/api/session/configuration?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    getSessionImage(imageId, sessionId) {
        return axios.get(packageJson.service.urlScanner + '/api/session/image/'+imageId+'?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    getSessionMetaData(imageId, sessionId) {
        return axios.get(packageJson.service.urlScanner + '/api/session/metadata/'+imageId+'?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    stopScanning(sessionId) {
        return axios.post(packageJson.service.urlScanner + '/api/session/StopScan?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }

    endSession(sessionId) {
        return axios.delete(packageJson.service.urlScanner + '/api/session?SessionId='+sessionId).then((res) => res.data).catch(err => err);
    }
    
}
